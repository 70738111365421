import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Tile} from './tile';


@Component({
  selector: 'app-tile',
  templateUrl: './tile.component.html',
  styleUrls: ['./tile.component.css'],
})
export class TileComponent implements OnInit {

  @Input() tile: Tile;
  navigationUrl: string;
  urlQueryParams = {};

  constructor(private router: Router) {
  }

  ngOnInit() {
    this.prepareNavigationUrl();
  }

  /**
   * This function prepares the "urlQueryParams" variable required for link navigation.
   */
  prepareNavigationUrl() {
    if (this.tile) {
      this.tile.queryParams?.forEach((param, index) => {
        this.urlQueryParams[param.key] = param.value;
      });
    }
  }

}
