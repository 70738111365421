import { CACustomer } from './customer-allocation-customer-model';
import { CARoute } from './customer-allocation-route-model';
import { CAAdditionalEmailAddress } from './customer-allocation-additional-email-address-model';
import { CAEmailAddress } from './customer-allocation-email-address-model';
import { CAReason } from './customer-allocation-reason-model';

export class OdAllocationModel {
  allocationId: string;
  brand: string;
  customer: CACustomer = new CACustomer();
  serviceContract: ServiceContract = new ServiceContract();
  route: CARoute = new CARoute();
  status: OdStatus = new OdStatus();
  cargoType: string;
  equipmentSize: string;
  nac: string;
  opportunityId: string;
  product: Product = new Product();
  allocationType: string;
  comments: string;
  cluster: string;
  startDate: string;
  endDate: string;
  startWeek: number;
  endWeek: number;
  confirmationComments: string;
  allocationBuffer: number;
  allMACBufferPercentage?: number;
  seasonality: number;
  confirmedBy: string;
  volumeFFE: number;
  requestedVolumeFFE: number;
  requestedStartDate: string;
  requestedEndDate: string;
  additionalEmailAddress: CAAdditionalEmailAddress;
  salesEmailAddress: CAEmailAddress = new CAEmailAddress();
  notifyCounterOfferAction: boolean;
  creationDate: string;
  updatedDate: string;
  createdBy: string;
  updatedBy: string;
  useNAPCustomerCode?: boolean;
  odAllocation: OdAllocation[];
  multiOdAllocation?: OdAllocation;
  actionReason: CAReason = new CAReason();
  requestValidationStatus: boolean;
  version = 0;
  deliveryPromise?: string;
  invalidODCombinationMessage?: string;
  isSporadicTypeFiling:boolean;
  isNOR: boolean;
  renegotiationDirection: number;
}

export class OdAllocation {
  origin: PortLocation;
  destination: PortLocation;
  origins?: OriginLocation[];
  destinations?: DestinationLocation[];
  steeredServiceStatus: string;
  service?: Service[];
  forecastMinFFE = 0;
  forecastMaxFFE = 0;
  odgName: string;
  weeklyVolume: WeeklyVolume[];
  requestedWeeklyVolume: WeeklyVolume[];
  totalVolumeFFE: number;
  requestedTotalVolumeFFE: number;
  maxWeeklyVolumeFFE: number;
}

/** This class is used only in the multi od scroll component */
export class MultiLocation {
  geoId: string;
  name: string;
  rkstCode: string;
  isConfirmed: boolean;
  status: string;
  fromServiceMode: string;
  toServiceMode: string;
}

export class Location {
  geoId: string;
  name: string;
  rkstCode: string;
  isConfirmed?: boolean;
  status?: string;
  isDisabled?: boolean;
}
export class PortLocation extends Location {
  [key: string]: any;
  serviceMode?: string; /** both from/to */
  portRkstCode?: string; /** both firstLoadPort/ lastDischargePort */
}

export class OriginLocation extends Location {
  fromServiceMode: string;
  firstLoadPort: Location;
  isNAPLocation: boolean = false;
}

export class DestinationLocation extends Location {
  toServiceMode: string;
  lastDischargePort: Location;
  isNAPLocation: boolean = false;
}

export class Port {
  geoId: string;
  name: string;
  rkstCode: string;
}

export class WeeklyVolume {
  weekNumber: number;
  volumeFFE: number;
}

export class Service {
  code: string;
  direction: string;
  name: string;
  isSteeredLeg: boolean;
}

export class OdStatus {
  code: string;
  description: string;
  id: number;
}

export class ServiceContract {
  contracts: Contract[];
}

export class Contract {
  code: string;
  name: string;
}

export class Product {
  code: string;
  name: string;
  version: string;
}
