import {Component, EventEmitter, Output} from '@angular/core';
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css']
})
export class LogoutComponent {

  @Output() cancelLogOut: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private msalService: MsalService) {
  }

  logout(){
    sessionStorage.clear();
    localStorage.clear();
    this.msalService.logoutPopup({
      mainWindowRedirectUri: "/"
    });
  }

  cancelLogout() {
    this.cancelLogOut.emit(true);
  }

}
