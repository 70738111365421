import {Injectable} from '@angular/core';
import { MAERSK, SEALAND, Theme, HAS } from './theme';

@Injectable()
export class ThemeService {
  private activeTheme: Theme = MAERSK;
  private availableThemes: Theme[] = [MAERSK, SEALAND, HAS];

  /**
   * setSealandTheme() - Method to set theme as sealand
   */
  setSealandTheme(): void {
    this.setActiveTheme(SEALAND);
  }

  /**
   * setMaerskTheme() - Method to set theme as maersk
   */
  setMaerskTheme(): void {
    this.setActiveTheme(MAERSK);
  }

  /**
   * setSealandTheme() - Method to set theme as sealand
   */
  setHamburgSudTheme(): void {
    this.setActiveTheme(HAS);
  }

  /**
   * setDefaultTheme() - Method to set default theme as maersk
   */
  setDefaultTheme(): void {
    this.setActiveTheme(MAERSK);
  }

  /**
   * getActiveThemeProperties() - Method to return active theme
   */
  getActiveThemeProperties(): Theme {
    return this.activeTheme;
  }

  /**
   * setActiveTheme() - Method to set active theme
   */
  setActiveTheme(theme: Theme): void {
    this.activeTheme = theme;
  }
}
