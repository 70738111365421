import {Component, OnInit} from '@angular/core';
import {PageLoadMessageService} from '../common-services/page-load-message.service';
import {Profile, UserInfo} from '../authentication/user.modal';
import {DowntimeNotification} from '../common-services/downtime.model';
import {ReleaseUpdatesService} from '../common-services/release-updates.service';

import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';

import {ThemeService} from '../themes/theme.service';
import {Theme} from '../themes/theme';
import {UserRoleService} from '../authorization/user-role.service';
import {applicationProperties} from '../../application-properties';
import { environmentEndpoints } from '../../environments/environment.endpoints';
import { environment } from '../../environments/environment';
import { selectBranding, AppState } from '../ngrx/state/index';
import { DomainService } from '../authorization/domain.service';
import { MsalService } from '@azure/msal-angular';

const {imgURL} = environmentEndpoints;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  userInfo: UserInfo;
  loading: boolean = false;
  userIcon = imgURL + 'user.png';
  downtimeNotifications: DowntimeNotification[];
  notificationCount: number;
  showLogoutConfirmation: boolean = false;
  brandLogo: string;
  userRoleList: string[] = [];
  _activeTheme$: Observable<Theme>;

  constructor( 
    private pageLoadMessageService: PageLoadMessageService, 
    private releaseUpdatesService: ReleaseUpdatesService,
    private themeService: ThemeService, 
    private userRoleService: UserRoleService,
    private domainService: DomainService,
    private store: Store<AppState>,
    private msalService: MsalService) {
    this._activeTheme$ = this.store.pipe(select(selectBranding));
  }

  ngOnInit() {
    if (environment.skipAuthentication) {
      this.userInfo = new UserInfo();
      this.userInfo.authenticated = true;
      this.userInfo.username = 'Test User';
      this.userInfo.profile = new Profile();
      this.userInfo.profile.name = 'John Doe';
    } else {
      this.userRoleService.userRoles.subscribe(roles => {
        this.userRoleList = roles
          .filter(role => role.roleName !== applicationProperties.guestUser)
          .map(role => role.roleName);
      this.userInfo = new UserInfo();
      this.userInfo.authenticated = true;
      this.userInfo.profile = new Profile();
      if( this.msalService.instance && this.msalService.instance.getActiveAccount()){
        this.userInfo.profile.name = this.msalService.instance.getActiveAccount().name;
      }      
      this.userInfo.profile.roles = this.renameTradeManagerRoleToProductManager(this.userRoleList);
      });
    }

    this.releaseUpdatesService.getDowntime().subscribe(response => {
      this.downtimeNotifications = response.DowntimeNotifications;
      if (this.downtimeNotifications) {
        this.notificationCount = this.downtimeNotifications.length;
      }
    });

    this.pageLoadMessageService.isPageLoading.subscribe(flag => {
      this.loading = flag;
    });
    this.setBrandLogo();
  }

  /**
   * logoutConfirmationbox() - Method to show User Confirmation for Logout
   */
  logoutConfirmationbox() {
    this.showLogoutConfirmation = true;
  }

  /**
   * hideLogOutConfirmationBox() - Method to hide User Confirmation for Logout
   */
  hideLogOutConfirmationBox() {
    this.showLogoutConfirmation = false;
  }

  /**
   * setBrandLogo() - Method to will set the logo based on active theme
   */
  setBrandLogo() {
    this._activeTheme$.subscribe( (res: Theme) => {
      this.brandLogo = res ? res.logo : '';
    })
  }

  /** This function re-names trade manager role to product manager */
  renameTradeManagerRoleToProductManager(userRoles: string[]): string[] {
    userRoles?.forEach((role, i) => {
      if (userRoles[i].trim().toLowerCase() === 'trademanager') {
        userRoles[i] = 'ProductManager';
      }
    });
    return userRoles;
  }

}
