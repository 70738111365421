import {Injectable} from '@angular/core';

export const locationStatus = {
  ADDED: 'ADDED',
  CONFIRMED: 'CONFIRMED',
  DELETE_REQUESTED: 'DELETE_REQUESTED',
  DELETED: 'DELETED'
};

export const accelerateStatuses = {
  PENDING: 'PENDING',
  CONFIRMED: 'CONFIRMED',
  REJECTED: 'REJECTED',
  CANCELLED: 'CANCELLED',
  COUNTER_OFFERED: 'COUNTER_OFFERED'
};

export const flexHubStatuses = {
  PENDING: 'PENDING',
  CONFIRMED: 'CONFIRMED',
  REJECTED: 'REJECTED',
  CANCELLED: 'CANCELLED',
  COUNTER_OFFERED: 'COUNTER_OFFERED'
};

/** Approve and reject actions are performed when accelerate allocation is in pending state,
 *  cancel action is performed on confirmed accelerate allocations.
 */
export const accelerateActions = {
  approve: 'APPROVE',
  reject: 'REJECT',
  cancel: 'CANCEL',
  editConfirmed: 'EDIT_CONFIRMED',
  counter: 'COUNTER',
  acceptCounter: 'ACCEPT_COUNTER',
  rejectCounter: 'REJECT_COUNTER'
};

export const reasons = {
  CANCEL: 'CANCEL',
  REJECT: 'REJECT',
  COUNTER_OFFER: 'COUNTER_OFFER',
  REJECT_COUNTER: 'REJECT_COUNTER',
  COUNTER_OFFER_IRREGULAR: 'COUNTER_OFFER_IRREGULAR',
  RENEGOTIATE_CONFIRMED_REQUEST: 'RENEGOTIATE',
  REJECT_REQUEST_RENEGOTIATION: 'REJECT_RENEGOTIATE',
  RENEGOTIATE_CONFIRMED_IRREGULAR_REQUEST: 'RENEGOTIATE_CONFIRMED_IRREGULAR_REQUEST',
  EDIT_CONFIRMED: 'EDIT_CONFIRMED',
  UPDATE_CONFIRMED_REQUEST: 'UPDATE_CONFIRMED'
};

export const actionOnRequest = {
  ACCEPT: 'ACCEPT',
  REJECT: 'REJECT',
  RENEGOTIATE: 'RENEGOTIATE',
  APPROVE: 'APPROVE'
};

export const allowedActionForState = {
  PENDING: {
    action: ['edit', 'counter', 'cancel', 'reject', 'confirm'],
    permission: {
      counter: ['CounterOfferAllocation'], cancel: ['CancelDraftOrPendingAllocation'],
      reject: ['RejectAllocation'], confirm: ['ConfirmAllocation'],
      edit: ['EditPendingAllocation']
    }
  },
  CONFIRMED: {
    action: [,'expire', 'cancel', 'update', 'renegotiation', 'editConfirmed'],
    permission: {
      cancel: ['CancelConfirmedAllocation'], update: ['UpdateConfirmedAllocation'],
      renegotiation: ['RenegotiateConfirmedAllocation'], editConfirmed: ['EditConfirmedAllocation'],
      expire: ['ODPilotUser']
    }
  },
  REJECTED: { action: [], permission: { reject: [] } },
  CANCELLED: { action: [], permission: { cancel: [] } },
  RENEGOTIATION: {
    action: ['editRenegotiated', 'rejectRenegotiation', 'acceptRenegotiation', 'cancelRenegotiation'],
    permission: {
      editRenegotiated: ['EditRenegotiatedAllocation', 'EditAcceptRenegotiation'],
      rejectRenegotiation: ['RejectRenegotiation'], acceptRenegotiation: ['AcceptRenegotiation'],
      cancelRenegotiation: ['CancelRenegotiation']
    }
  },
  COUNTER_OFFERED: {
    action: ['rejectCounterOffer', 'acceptCounterOffer'],
    permission: { rejectCounterOffer: ['RejectCounterOffer'], acceptCounterOffer: ['AcceptCounterOffer'] }
  }
};

export const allowedSecondaryBtnOnPrimaryBtnClick = {
  RENEGOTIATION: {
    editRenegotiated: {
      action: ['undo', 'submitAndAcceptRenegotiation', 'submitEditedRenegotiation'],
      permission: { undo: [], submitAndAcceptRenegotiation: ['EditAcceptRenegotiation'], submitEditedRenegotiation: ['EditRenegotiatedAllocation'] }
    }
  }
};

export const allowedActionForUnderApprovalAccelerateStates = {
  PENDING: {
    action: ['approve', 'reject', 'counter'],
    permission: {
      approve: ['ConfirmAccelerate'],
      reject: ['RejectAccelerate'],
      counter: ['CounterOfferAccelerate']
    }
  },
  COUNTER_OFFERED:{
    action: ['rejectCounterOffer', 'acceptCounterOffer'],
    permission: { rejectCounterOffer: ['RejectCounterOffer'], acceptCounterOffer: ['AcceptCounterOffer'] }
  }
};

export const allowedActionForExistingAccelerateStates = {
  CONFIRMED: {
    action: ['cancel', 'editConfirmed'],
    permission: {
      cancel: ['CancelConfirmedAccelerate'],
      editConfirmed: ['EditConfirmedAccelerate']
    }
  }
};

export const createAccelerateAllocation = {
  permission: ['CreateAccelerate']
};

export const productProperties = {
  volume: 'Vol',
  report: 'Report',
  buffer: 'Buffer',
  renegotiation: 'Renegotiation',
  weeklySplit: 'WeeklySplit',
  maxVolLimit: 'MaxVolLimit',
  updateAllocationBuffer: 'UpdateAllocationBuffer',
  remainingVolumeAdjust: 'RemainingVolumeAdjust',
  downloadExcel: 'DownloadExcel',
  importVolFromExcel: 'ImportVolFromExcel',
  bufferLowerLimit: 'BufferLowerLimit',
  bufferUppperLimit: 'BufferUppperLimit',
  bufferDefault: 'BufferDefault',
  disallowed: 'Disallowed',
  active: 'active',
  flexible: 'FlexibleAlloc',
  seasonal: 'SeasonalAlloc',
  unlimited: 'UnlimitedAlloc',
  basic: 'BasicAlloc',
  sporadic: 'SporadicAlloc'
};


export const product = {
  BasicAlloc: {
    weeklySplit: {
      display: true
    },
    maxVolLimit: {
      display: false
    },
    updateAllocationBuffer: {
      display: true
    },
    remainingVolumeAdjust: {
      display: false
    },
    downloadExcel: {
      display: false
    },
    importVolFromExcel: {
      display: false
    },
    renegotiation: {
      display: true,
      upwardChange: false
    },
    seasonality: {
      display: false,
    },
    unlimitedWarningMsg: {
      display: false
    },
    exception: {
      display: true,
      compareWithWeeklyMax: false,
      compareWithPreviousValue: true
    }
  },
  SeasonalAlloc: {
    weeklySplit: {
      display: true
    },
    maxVolLimit: {
      display: false
    },
    updateAllocationBuffer: {
      display: true
    },
    remainingVolumeAdjust: {
      display: true
    },
    downloadExcel: {
      display: true
    },
    importVolFromExcel: {
      display: true
    },
    renegotiation: {
      display: true,
      upwardChange: true
    },
    seasonality: {
      display: true,
      lowerLimit: 100,
      upperLimit: 500
    },
    unlimitedWarningMsg: {
      display: false
    },
    exception: {
      display: true,
      compareWithWeeklyMax: true,
      compareWithPreviousValue: false
    }
  },
  FlexibleAlloc: {
    weeklySplit: {
      display: false
    },
    maxVolLimit: {
      display: true
    },
    updateAllocationBuffer: {
      display: false
    },
    remainingVolumeAdjust: {
      display: true
    },
    downloadExcel: {
      display: false
    },
    importVolFromExcel: {
      display: false
    },
    renegotiation: {
      display: true,
      upwardChange: true
    },
    seasonality: {
      display: false,
    },
    unlimitedWarningMsg: {
      display: false
    },
    exception: {
      display: false,
      compareWithWeeklyMax: false,
      compareWithPreviousValue: false
    }
  },
  UnlimitedAlloc: {
    weeklySplit: {
      display: true
    },
    maxVolLimit: {
      display: false
    },
    updateAllocationBuffer: {
      display: true
    },
    remainingVolumeAdjust: {
      display: true
    },
    downloadExcel: {
      display: true
    },
    importVolFromExcel: {
      display: true
    },
    renegotiation: {
      display: true,
      upwardChange: true
    },
    seasonality: {
      display: false,
    },
    unlimitedWarningMsg: {
      display: true
    },
    exception: {
      display: false,
      compareWithWeeklyMax: false,
      compareWithPreviousValue: false
    }
  }
};

export const excelVersion = {
  TEMPLATE_VERSION: 'Version-1.0.0'
};

export const downloadExcelConstants = {
  OD_REQUEST_EXCEL_DOWNLOAD_LIMIT: 'OD_REQUEST_EXCEL_DOWNLOAD_LIMIT',
  STEERED_REQUEST_EXCEL_DOWNLOAD_LIMIT: 'STEERED_REQUEST_EXCEL_DOWNLOAD_LIMIT',
  BI_LINK: 'https://teamsite.maerskgroup.com/:x:/r/teams/FutureProduct/_layouts/15/Doc.aspx?sourcedoc=%7B7A412FED-671A-43DF-B5D1-8AA61AFB51F1%7D&file=Cat%20Cube_LATEST.xlsx&action=default&mobileredirect=true',
};

export const applicableFilters = {
  STEERED: {
    hideBrandFilter: false,
    hideRouteFilter: false,
    hideCustomerNameFilter: false,
    hideScvCodeFilter: false,
    hideStatusFilter: false,
    hideServiceFilter: false,
    hideCargoTypeFilter: false,
    hideClusterFilter: false,
    hideValuePropositionFilter: false,
    hideDateValidityFilter: false,
    hideCustomerTypeFilter: false,
    hideServiceContractNumberFilter: false,
    hideCreatedByIdFilter: false,
    hideProductFilter: false,
    hideSporadicTypeFilingFilter: true,
    hideValidationStatusFilter: false,
    hideYtdComplianceFilter: false,
    hideAvgComplianceFilter: false,
    hideAllocationTypeFilter: true,
    hideDeliveryPromiseFilter: true,
    hidePreviousMonthsComplianceFilter: false,
    hideResponsibleSalesOwnerFilter: false,
    hideAllocationVariantFilter: false,
    hideValidityTypeFilter: false,
    hideVerticalsFilter: false
  },
  OD: {
    hideBrandFilter: false,
    hideRouteFilter: false,
    hideCustomerNameFilter: false,
    hideScvCodeFilter: false,
    hideStatusFilter: false,
    hideServiceFilter: false,
    hideCargoTypeFilter: false,
    hideClusterFilter: false,
    hideValuePropositionFilter: false,
    hideDateValidityFilter: false,
    hideCustomerTypeFilter: false,
    hideServiceContractNumberFilter: false,
    hideCreatedByIdFilter: false,
    hideProductFilter: false,
    hideSporadicTypeFilingFilter: false,
    hideValidationStatusFilter: false,
    hideYtdComplianceFilter: true,
    hideAvgComplianceFilter: true,
    hideAllocationTypeFilter: false,
    hideDeliveryPromiseFilter: false,
    hidePreviousMonthsComplianceFilter: false,
    hideResponsibleSalesOwnerFilter: false,
    hideAllocationVariantFilter: false,
    hideValidityTypeFilter: false,
    hideVerticalsFilter: false
  },
  ODVAS: {
    hideBrandFilter: true,
    hideRouteFilter: false,
    hideCustomerNameFilter: false,
    hideScvCodeFilter: false,
    hideStatusFilter: false,
    hideServiceFilter: true,
    hideCargoTypeFilter: false,
    hideClusterFilter: true,
    hideValuePropositionFilter: true,
    hideDateValidityFilter: true,
    hideCustomerTypeFilter: true,
    hideServiceContractNumberFilter: false,
    hideCreatedByIdFilter: true,
    hideProductFilter: true,    
    hideSporadicTypeFilingFilter: true,
    hideValidationStatusFilter: true,
    hideYtdComplianceFilter: true,
    hideAvgComplianceFilter: true,
    hideAllocationTypeFilter: true,
    hideDeliveryPromiseFilter: true,
    hidePreviousMonthsComplianceFilter: true,
    hideResponsibleSalesOwnerFilter: true,
    hideAllocationVariantFilter: true,
    hideValidityTypeFilter: true,
    hideVerticalsFilter: false
  }
};

export const applicationConstants = {
  aoRouteConstant: {
    numOfWeeks: 4
  },
  service: {
    STEERED: { code: 'STEERED', label: 'cat._pending_tray.labels._steered_based', id: 'steeredSelection' },
    OD: { code: 'OD', label: 'cat._pending_tray.labels._od_based', id: 'odSelection' },
    ODVAS: { code: 'ODVAS', label: 'cat._pending_tray.labels._od_vas', id: 'odVasSelection'}
  },
  columnParam : {
    steered: 'creationDate',
    od: 'creationDate',
    odVas: 'createdDateTime'
  },
  applicationEnvironments: {
    fcp: 'FCP',
    cmd: 'CMD',
    afls: 'AFLS',
    cav: 'CAV',
    any: 'ANY',
    sfdc: 'SFDC',
    cat: 'CAT'
  },
  textToEllipsisConverter: {
    serviceContractName: 15
  },
  commentsMaxLength: {
    editConfirmed: 2000,
    others: 1000
  },
  requestUrl: {
    customerAllocation: {
      create: 'create',
      edit: 'edit',
      copy: 'copy'
    }
  },
  imagePath: {
    copyLink: '../assets/img/Combined_Shape1.svg',
    createLink: '../assets/img/Combined_Shape2.png',
    pendingTrayLink: '../assets/img/Combined_Shape2.png',
    mslBrand: '/assets/img/maersk_brand_view.png',
    sclBrand: '/assets/img/safmarine_brand_view.png'
  },
  tileUrl: {
    copyLink: '/customerAllocation/copy',
    createLink: '/customerAllocation/create',
    pendingTrayLink: '/pendingTray'
  },
  odCreate: {
    copyLink: '/allocations/copy',
    createLink: '/allocations/create'
  },
  requestDetailBackNavigation: {
    link: {
      pendingTray: '/pendingTray',
      requestCurtailment: '/requestCurtailment/curtail'
    },
    caption: {
      pendingTray: 'PENDING TRAY',
      requestCurtailment: 'REQUEST CURTAILMENT'
    }
  },

  userRoles: [
    'RevenueManager',
    'TradeManager',
    'SalesAgent',
    'GSC',
  ],

  brandsToExclude: 'HamburgSud',

  userPermissions:
  {
    viewAllocation: 'ViewAllocation',
    confirmAllocation: 'ConfirmAllocation',
    rejectAllocation: 'RejectAllocation',
    counterOfferAllocation: 'CounterOfferAllocation',
    cancelConfirmedAllocation: 'CancelConfirmedAllocation',
    editDraftAllocation: 'EditDraftAllocation',
    updateAllocationBuffer: 'UpdateAllocationBuffer',
    createDraftAllocation: 'CreateDraftAllocation',
    acceptRenegotiation: 'AcceptRenegotiation',
    rejectRenegotiation: 'RejectRenegotiation',
    editConfirmedAllocation: 'EditConfirmedAllocation',
    renegotiateConfirmedAllocation: 'RenegotiateConfirmedAllocation',
    addSalesFeedback: 'AddSalesFeedback',
    acceptRejectFeedback: 'AcceptRejectFeedback',
    viewSalesFeedback: 'ViewSalesFeedback',
    updateClusterInConfirmedRequest: 'UpdateClusterInConfirmedRequest'
  }
  ,

  requestValidationStatus: {
    validated: 'Y',
    notValidated: 'N',
    failed: 'F'
  },

  cavWeekNumberValidationLimit: {
    pastWeeks: 12,
    futureWeeks: 52,
    pastDays: 100,
    futureDays: 400
  },

  maerskDomainBrands: [
    'MSL',
    'SCL'
  ],

  sealandDomainBrands: [
    'SGL',
    'MCC',
    'SEA'
  ],

  hsudBrands: [
    'HAS'
  ],

  weeksConst: {
    numberOfWeekInYear: 52
  },
  cargoTypes:{
    'ALL': 'All',
    'DRY': 'Dry',
    'REEFER': 'Reefer',
    'NOR': 'NOR'
  }
  
};

export const reasonHeader = {
  COUNTER_OFFERED: 'Counter offer',
  CANCELLED: 'Cancellation',
  REJECTED: 'Rejection',
  EXPIRED: 'Expiration',
  RENEGOTIATION: 'Renegotiation',
  CONFIRMED: 'Confirmation'
};

export const OdLevelOfMODFiling = {
  ROUTE_DIRECTION_LEVEL: 'route-direction-level',
  OD_GROUP_LEVEL: 'od-group-level',
  
};

export const restrictedRequestStatusForAdditionalEmail = ['REJECTED', 'EXPIRED', 'CANCELLED'];

export const restrictedRequestStatusForAdditionalEmailOD = ['REJECTED', 'EXPIRED', 'CANCELLED'];

export const restrictedRequestStatusForResponsibleSalesEmailOD = ['REJECTED', 'EXPIRED', 'CANCELLED'];

export const customerSelectionType = {CODE: 'CODE', NAME: 'NAME', SERVICE_CONTRACT: 'SERVICE_CONTRACT', MANUAL: 'MANUAL'};
export const SEGMENT_CLASSIFICATION = {MSTS: 'MSTS', CUST: 'CUST', SERV: 'SERV', VALP: 'VALP'};

@Injectable()
export class ConstantsService {

  getExcelVersion() {
    return excelVersion.TEMPLATE_VERSION;
  }

  getApplicationConstants() {
    return applicationConstants;
  }

}
