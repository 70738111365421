import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';

import {environment} from '../../../environments/environment';
import {environmentEndpoints} from '../../../environments/environment.endpoints';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class FeedbackService {

  /**
   * @constructor
   * @param http
   */
  constructor(private http: HttpClient) {
  }

  /**
   * submitFeedback() - Method to submit the feedback
   */
  submitFeedback(feedbackReqObj, isHamburgSud: boolean): Observable<HttpResponse<Object> | Response> {
    const {api_cat_gw_url} = environment;
    const {feedback, hsudFeedBack, api_notification_root} = environmentEndpoints;


    return this.http.post( api_cat_gw_url + api_notification_root + (isHamburgSud ? hsudFeedBack : feedback), feedbackReqObj)
    .pipe(
      map((response) => response),
      catchError(this.errorHandler));
  }

  /**
   * errorHandler() - Method to handle errors in the services
   * @param error
   */
  errorHandler(error: Response | any) {
    return of(error);
  }

}
