import {Injectable} from '@angular/core';
import {UserRole} from './user-role.model';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class UserRoleService {

  private messageSource = new BehaviorSubject<UserRole[]>([]);
  userRoles = this.messageSource.asObservable();

  changeMessage(userRoles: UserRole[]) {
    this.messageSource.next(userRoles);
  }
}
