import {Injectable} from '@angular/core';
import {DuplicateErrorResponse} from '../exceptions/duplicate-error.response';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class MessageService {

  errors: string[] = [];
  errorsHidden: boolean = true;
  connectionErrors: string[] = [];
  connectionErrorHidden: boolean = true;

  duplicateErrorMessageSource: BehaviorSubject<DuplicateErrorResponse> = new BehaviorSubject<DuplicateErrorResponse>(undefined);
  duplicateErrorResponse = this.duplicateErrorMessageSource.asObservable();

  duplicateActionAdapter: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);

  productErrorMessageSource: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  productErrorResponse = this.productErrorMessageSource.asObservable();
  productErrorHidden: boolean = true;
  duplicateErrorHidden: boolean = true;

  info: string[] = [];
  infoDisplay: boolean = false;


  /**
   * addErrors() - Method to add  errors
   * @param errorMessage
   */
  addErrors(errorMessage: string) {
    this.errorsHidden = false;
    this.errors.push(errorMessage);
    this.errors = Array.from<string>(new Set(this.errors));
  }

  /**
   * clearErrors() - Method to clear arrays
   */
  clearErrors() {
    this.errors = [];
    this.hideErrors();
  }

  /**
   * clearAllErrors() - Method to clear all the errors in message service
   */
  clearAllErrors() {
    this.connectionErrorHidden = true;
    this.connectionErrors = [];
    this.clearErrors();
    this.hideConnectionErrors();
    this.hideErrors();
    this.hideDuplicateError();
    this.duplicateErrorFound(undefined);
    this.clearInfo();
  }

  /**
   * addConnectionErrors() - Method to add Connection Errors
   * @param connectionError Met
   */
  addConnectionErrors(connectionError: string) {
    this.connectionErrorHidden = false;
    if(!this.connectionErrors.includes(connectionError)){
      this.connectionErrors.push(connectionError);
    }
  }

  /**
   * hideConnectionErrors() - Method to hide connection errors
   */
  hideConnectionErrors() {
    this.connectionErrorHidden = true;
  }

  /**
   * hideErrors() - Method to hide errors
   */
  hideErrors() {
    this.errorsHidden = true;
  }

  /**
   * hideDuplicateError() - Method to hide duplicate errors
   */
  hideDuplicateError() {
    this.duplicateErrorHidden = true;
  }

  duplicateErrorFound(duplicateErrorResponse: DuplicateErrorResponse) {
    this.duplicateErrorHidden = false;
    this.duplicateErrorMessageSource.next(duplicateErrorResponse);
  }

  /**
   * This method will set the productErrorMessageSource with error response.
   * @param productErrorResponse
   */
  productErrorFound(productErrorResponse: DuplicateErrorResponse) {
    this.productErrorHidden = false;
    this.productErrorMessageSource.next(productErrorResponse);
  }

  addInfo(info: string) {
    this.infoDisplay = true;
    this.info.push(info);
  }

  clearInfo() {
    this.info = [];
    this.infoDisplay = false;
  }

}
