import { applicationProperties } from '../../application-properties';
import { KeyValueModel } from '../common-models/key-value-model';
import { KeyAliasValueModel } from "../common-models/key-alias-value-model";

export enum AgreementType {
  REGULAR = 0,
  IRREGULAR = 1
}

export const od = {
  startChar: '5',
  sfdcMaxCharLength: 13
};
export const allocationBufferConst = {
  maxBufferValue: 200,
  minBufferValue: 0,
  incrementor: 5,
  maxLength: 2
};

export const weeklyMaxConst = {
  maxLength: 9,
  minValue: 0,
  maxValue: 10000,
  incrementer: 1
};

export const seasonalityConst = {
  incrementor: 5,
  maxLength: 3,
  pointFive: 0.5,
  pointZero: 0.0
};

export const curtailmentExclusionConst = {
  exclusionConst: ['Include for Curtailment', 'Exclude for Curtailment']
};

export const historyCurtailmentExclusionConst = {
  curtailed: 'Excluded for Curtailment',
  kept: 'Included for Curtailment',
  pending: 'Pending'
};
export const defaultPendingTrayTableValues = {
  sortOrder: 0,
  sortParam: 'creationDate',
};

export const downloadBarConst = {
  maxNumberOfRecords: 20,
  maxSetSize: 1
};

export const cav = {
  numberOfWeekLimit: 13,
  ffePercentageLimit: 81
};

export const defaultPreviousMonthsCompliance = {
  pendingTray: '',
  curtailment: '85'
};

export const contractOverview = {
  status: ['COUNTER_OFFERED', 'PENDING', 'CONFIRMED', 'RENEGOTIATION']
};

export const requestCurtailmentCount = {
  selectedRequestCountOne: '1'
};

export const requestStatuses = {
  draft: 'DRAFT',
  pending: 'PENDING',
  confirmed: 'CONFIRMED',
  rejected: 'REJECTED',
  counterOffered: 'COUNTER_OFFERED',
  expired: 'EXPIRED',
  cancelled: 'CANCELLED',
  renegotiation: 'RENEGOTIATION',
  curtailment: 'CURTAILMENT'
};

export const errorCode = {
  DUPLICATE001: 'DUPLICATE001',
  DUPLICATE002: 'DUPLICATE002',
  ERROR_PRODUCT_CHANGED: 'ERROR_PRODUCT_CHANGED'
};

export const tooltipConst = {
  numOfCols: 3,
  two: 2,
  bottomAdjustment: 50
};

export const regExpressionsPatternConst = {
  regExpPatternForFreeTextWithRestrictedSpclChars: /[^\sa-zA-Z0-9.,:?;%/()'!""-]/g,
  nacRegex: /^([a-zA-Z 0-9.,:?;%/()'!\-\"]){0,100}$/,
  customerCodeWhiteListRegex: /^[A-Za-z0-9\-]*$/,
  sfdcOpportunityRegex: /^OP-\w{2}-\w{7}$/,
  customerCodeWhiteListRegexToReplace: /[^a-zA-Z0-9-]/g,
  customerCodeBlackListRegex: /[`~¤!æÆøØ@#$%^&*()_|+\=?;´\s:'",.<>\{\}\[\]\\\/]/gi,
  serviceContractNumbersBlackListRegex: /[`[A-Za-z~¤!½§¨¨æÆøØ@#$%^&*()_|+\=?;´\s:'",.<>\{\}\[\]\\\/]/gi,
  serviceContractNumbersWhiteListRegexToReplace: /[^0-9-]/g,
  serviceContractNumbersWhiteListRegexToReplaceHAS: /[^a-z0-9]/gi,
  emailAddressRegex: /^[A-Za-z0-9._%+-]+(@lns.maersk.com|@maersk.com|@safmarine.com|@sealandmaersk.com){1}$/,
  emailAddressRegexHAS: /^[A-Za-z0-9._%+-]+(@lns.maersk.com|@maersk.com|@safmarine.com|@sealandmaersk.com|@hamburgsud.com){1}$/,
  routeDirectionRegex: /[&\/\\#,+()$~%.'":*?<>{}\s-]/g,
  numericInputRegex: /[^\d]+/g,
  allocationInputRegex: /^\d+(\.[0,5]{1})?$/,
  twentyFtOrNotSpecifiedEqpSizeRegex: /^\d{0,7}(\.[5-5]{1,1})?$/,
  fortyFtOrNotSpecifiedEqpSizeRegex: /^\d{0,7}?$/
};

export const modalProperties = {
  REJECT: {
    action: 'REJECT',
    header: 'cat._request_details.modal._confirm_rejection_head',
    info: 'cat._request_details.modal._rejection_label',
    confirmButtonLabel: 'cat._request_details.modal._confirm_rejection',
    confirmButtonId: 'btnConfirmRejection',
    cancelButtonLabel: 'cat._request_details.modal._cancel',
    cancelButtonId: 'btnCancel',
    commentsId: 'ca-rejection-others-comments',
    url: applicationProperties.rejectCaRequest,
    redirectURL: '/pendingTray',
    redirectionMessage: 'cat._pending_tray.messages._rejection_message',
    commentsMaxLength: 1000
  },
  REJECT_COUNTER: {
    action: 'REJECT_COUNTER',
    header: 'cat._request_details.modal._confirm_rejection_head',
    info: 'cat._request_details.modal._please_specify_the_reason_for_rejection_of_the_request',
    confirmButtonLabel: 'CONFIRM REJECTION',
    confirmButtonId: 'btnCoConfirmRejection',
    cancelButtonLabel: 'CANCEL',
    cancelButtonId: 'btnCoCancelRejection',
    commentsId: 'co-rejection-others-comments',
    url: applicationProperties.rejectCoRequest,
    redirectURL: '/pendingTray',
    redirectionMessage: 'cat._pending_tray.messages._cancellation_message',
    commentsMaxLength: 1000
  },
  CANCEL: {
    action: 'CANCEL',
    header: 'cat._request_details.modal._confirm_cancellation',
    info: 'cat._request_details.modal._please_specify_the_reason_for_cancelling_the_request',
    confirmButtonLabel: 'cat._request_details.modal._confirm_cancel',
    confirmButtonId: 'btnConfirmCancellation',
    cancelButtonLabel: 'CANCEL',
    cancelButtonId: 'btnCancel',
    commentsId: 'otherCancelComments',
    url: '',
    redirectURL: '/pendingTray',
    redirectionMessage: 'cat._pending_tray.messages._cancellation_message',
    commentsMaxLength: 1000
  },
  COUNTER_OFFER: {
    action: 'COUNTER_OFFER',
    header: 'cat._request_details.modal._reason_for_counter_offer',
    info: 'cat._request_details.modal._please_specify_the_reason_for_submitting_a_counter_offer',
    confirmButtonLabel: 'SUBMIT COUNTER OFFER',
    confirmButtonId: 'submitCounterOffer',
    cancelButtonLabel: 'CANCEL',
    cancelButtonId: 'cancelCounterOffer',
    url: '',
    redirectURL: '',
    redirectionMessage: ''
  },
  COUNTER_OFFER_IRREGULAR: {
    action: 'COUNTER_OFFER_IRREGULAR',
    header: '',
    info: '',
    confirmButtonLabel: 'CONFIRM COUNTER OFFER',
    confirmButtonId: 'btnConfirmIrregularCounterOffer',
    cancelButtonLabel: 'CANCEL',
    cancelButtonId: 'cancelCounterOffer',
    url: '',
    redirectURL: '',
    redirectionMessage: ''
  },
  REJECT_RENEGOTIATE: {
    action: 'REJECT_RENEGOTIATE',
    header: 'cat._request_details.modal._confirm_rejection_head',
    info: 'cat._request_details.modal._please_specify_the_reason_for_rejection_of_the_request',
    confirmButtonLabel: 'CONFIRM REJECTION',
    confirmButtonId: 'btnRequestRenegotiateConfirmRejection',
    cancelButtonLabel: 'CANCEL',
    cancelButtonId: 'btnRequestRenegotiateCancelRejection',
    commentsId: 'request-renegotiate-rejection-others-comments',
    url: applicationProperties.rejectRequestRenegotiation,
    redirectURL: '/pendingTray',
    redirectionMessage: 'cat.request_renegotiation.messages.rejectionMessage',
    commentsMaxLength: 1000
  },
};

export const promptActions = {
  no_change_in_request_renegotiation: 'NO_CHANGE_IN_REQUEST_RENEGOTIATION',
  no_change_in_edit_cnf_request: 'NO_CHANGE_IN_EDIT_CONFIRMED_REQUEST',
  no_change_in_update_cnf_request: 'NO_CHANGE_IN_UPDATE_CONFIRMED_REQUEST',
  confirm_request_renegotiation: 'CONFIRM_REQUEST_RENEGOTIATION',
  confirm_co: 'CONFIRM_CO',
  confirm_ca: 'CONFIRM_CA',
  validate_afls_cmd_manually: 'MANUAL_VALIDATION',
  validate_afls_cmd_manual_exception: 'AFLS_CMD_MANUAL_VALIDATION_EXCEPTION',
  confirm_add_email: 'CONFIRM_ADD_EMAIL',
  mark_week_as_exception: 'MARK_WEEK_AS_EXCEPTION',
  cancel_renegotiation: 'CANCEL_RENEGOTIATION',
};

export const promptProperties = {

  CONFIRM_CA: {
    header: 'cat._request_details.modal._confirm_request',
    message: 'cat._request_details.modal._confirm_alert',
    additionalMessage: 'cat._request_details.modal._weekly_min_max_adjust_confirm_alert',
    cancelId: 'btn-cancel',
    confirmId: 'btn-proceeed',
    promptId: 'prompt-',
    cancelName: 'cancelCaApprovalPrompt',
    confirmName: 'confirmCaApprovalPrompt',
    confirmContent: '',
    cancelButtonText: 'cat._prompt_box._confirm_request._cancel',
    confirmButtonText: 'cat._prompt_box._confirm_request._confirm',
    commentsMaxLength: 1000
  },

  CONFIRM_CO: {
    header: 'cat._request_details.modal._confirm_request',
    message: 'cat._request_details.modal._confirm_alert',
    cancelId: 'btn-cancel-co',
    confirmId: 'btn-proceeed-co',
    promptId: 'prompt-',
    cancelName: 'cancelCaApprovalPrompt',
    confirmName: 'confirmCaApprovalPrompt',
    confirmContent: '',
    cancelButtonText: 'cat._prompt_box._confirm_request._cancel',
    confirmButtonText: 'cat._prompt_box._confirm_request._confirm',
    commentsMaxLength: 1000
  },

  CONFIRM_REQUEST_RENEGOTIATION: {
    header: 'cat._request_details.modal._confirm_request',
    message: 'cat._request_details.modal._confirm_alert',
    cancelId: 'btn-cancel-request-renegotiation',
    confirmId: 'btn-proceed-request-renegotiation',
    promptId: 'prompt-',
    cancelName: 'cancelRequestRenegotiationPrompt',
    confirmName: 'confirmRequestRenegotiationPrompt',
    confirmContent: '',
    cancelButtonText: 'cat._prompt_box._confirm_request._cancel',
    confirmButtonText: 'cat._prompt_box._confirm_request._confirm',
    commentsMaxLength: 1000
  },
  NO_CHANGE_IN_REQUEST_RENEGOTIATION: {
    message: 'cat._request_details.modal._continue_renegotiation_alert',
    cancelId: 'btn-cancel-no-change-renegotiation',
    confirmId: 'btn-proceeed-no-change-renegotiation',
    promptId: 'prompt-',
    cancelName: 'cancelRequestRenegotiationNoChangePrompt',
    confirmName: 'confirmRequestRenegotiationNoChangePrompt',
    confirmContent: '',
    cancelButtonText: 'cat._request_details.modal._cancel_renegotiation',
    confirmButtonText: 'cat._request_details.modal._continue_editing'
  },
  NO_CHANGE_IN_EDIT_CONFIRMED_REQUEST: {
    message: 'cat._request_details.modal._continue_renegotiation_alert',
    cancelId: 'btn-cancel-no-change-edit-confirmed',
    confirmId: 'btn-proceeed-no-change-edit-confirmed',
    promptId: 'prompt-',
    cancelName: 'cancelEditConfirmedRequestNoChangePrompt',
    confirmName: 'confirmEditConfirmedRequestNoChangePrompt',
    confirmContent: '',
    cancelButtonText: 'cat._request_details.modal._cancel_edit',
    confirmButtonText: 'cat._request_details.modal._continue_editing'
  },
  NO_CHANGE_IN_UPDATE_CONFIRMED_REQUEST: {
    message: 'cat._request_details.modal._continue_renegotiation_alert',
    cancelId: 'btn-cancel-no-change-update-confirmed',
    confirmId: 'btn-proceeed-no-change-update-confirmed',
    promptId: 'prompt-',
    cancelName: 'cancelUpdateConfirmedRequestNoChangePrompt',
    confirmName: 'confirmUpdateConfirmedRequestNoChangePrompt',
    confirmContent: '',
    cancelButtonText: 'cat._request_details.modal._cancel_edit',
    confirmButtonText: 'cat._request_details.modal._continue_editing'
  },
  AFLS_CMD_MANUAL_VALIDATION: {
    message: 'cat._prompt_box._manual_validation._confirmation_msg',
    cancelId: 'btn-cancel-validation',
    confirmId: 'btn-validation',
    promptId: 'prompt-',
    cancelName: 'cancelCaValidationPrompt',
    confirmName: 'confirmCaValidationPrompt',
    confirmContent: '',
    cancelButtonText: 'cat._prompt_box._manual_validation._cancel',
    confirmButtonText: 'cat._prompt_box._manual_validation._confirm',
    redirectionMessage: 'cat._prompt_box._manual_validation._success_message',
    redirectURL: '/customerAllocation/edit'
  },
  AFLS_CMD_MANUAL_VALIDATION_EXCEPTION: {
    message: 'cat._prompt_box._manual_validation_exception._message',
    cancelId: 'btn-cancel-validation',
    confirmId: 'btn-validation',
    promptId: 'prompt-',
    cancelName: 'cancelCaValidationPrompt',
    confirmName: 'confirmCaValidationPrompt',
    confirmContent: '',
    cancelButtonText: 'cat._prompt_box._manual_validation_exception._cancel',
    confirmButtonText: 'cat._prompt_box._manual_validation_exception._confirm',
    redirectURL: '/customerAllocation/edit'
  },
  CONFIRM_ADD_EMAIL: {
    header: 'cat._prompt_box._add_email_address._header',
    message: 'cat._prompt_box._add_email_address._message',
    cancelId: 'btn-cancel-add-email',
    confirmId: 'btn-proceed-add-email',
    promptId: 'prompt-',
    cancelName: 'cancelAddEmailPrompt',
    confirmName: 'confirmAddEmailPrompt',
    confirmContent: '',
    cancelButtonText: 'cat._prompt_box._confirm_request._cancel',
    confirmButtonText: 'cat._prompt_box._confirm_request._confirm',
    successMessage: 'cat._prompt_box._add_email_address._success_message'
  },
  DELETE_CA_REQUEST: {
    header: 'cat._prompt_box._delete_ca_request._header',
    cancelId: 'btn-cancel-delete-ca-request',
    confirmId: 'btn-proceed-delete-ca-request',
    promptId: 'prompt-',
    cancelName: 'cancelDeleteCaRequest',
    confirmName: 'confirmDeleteCaRequest',
    confirmContent: '',
    cancelButtonText: 'cat._prompt_box._delete_ca_request._cancel',
    confirmButtonText: 'cat._prompt_box._delete_ca_request._confirm',
    successMessage: 'cat._prompt_box._delete_ca_request._success_message',
    redirectURL: '/pendingTray'
  },
  MARK_WEEK_AS_EXCEPTION: {
    message_to_modify_exception_week: 'cat._prompt_box._exception_weeks._message_to_modify_exception_week',
    message_to_create_exception_week: 'cat._prompt_box._exception_weeks._message_to_create_exception_week',
    summary_seasonal: 'cat._prompt_box._exception_weeks._summary_seasonal',
    cancelId: 'btn-cancel-exception-week',
    confirmId: 'btn-proceed-exception-week',
    promptId: 'prompt-',
    cancelName: 'cancelExceptionWeek',
    confirmName: 'confirmExceptionWeek',
    cancelButtonText: 'cat._prompt_box._exception_weeks._cancel',
    confirmButtonText: 'cat._prompt_box._exception_weeks._confirm'

  },
  CURTAIL_REQ_SUBMIT: {
    header: 'cat._prompt_box._curtail_req_submit._header',
    confirmId: 'btnOk',
    promptId: 'promptCurtailReqSubmitConfirm',
    confirmName: 'confirmCurtailReqSubmitConfirm',
    confirmButtonText: 'cat._prompt_box._curtail_req_submit._ok',
    cancelId: '',
    cancelButtonText: 'cat._prompt_box._curtail_req_submit._no',
    message: 'cat._prompt_box._curtail_req_submit._message',
    commentsMaxLength: 2000
  },
  REQ_CURTAIL_CONFIRM: {
    header: 'cat._prompt_box._req_curtail_confirm._header',
    messageSingleReq: 'cat._prompt_box._req_curtail_confirm._message_single_req',
    messageMultiReq: 'cat._prompt_box._req_curtail_confirm._message_multi_req',
    confirmId: 'btnYes',
    promptId: 'promptCurtailReqSubmit',
    confirmName: 'confirmCurtailReqSubmit',
    confirmButtonText: 'cat._prompt_box._req_curtail_confirm._yes',
    cancelId: 'btnNo',
    cancelName: 'rejectCurtailmentReqSubmit',
    cancelButtonText: 'cat._prompt_box._req_curtail_confirm._no',
    commentsMaxLength: 2000
  },
  CURTAIL_REQ_SALES_FEEDBACK: {
    header: 'cat._prompt_box._curtail_req_sales_feedback._header',
    message: 'cat._prompt_box._curtail_req_sales_feedback._message',
    confirmId: 'reqSalesFeedbackbtnOk',
    promptId: 'promptCurtailReqSalesFeedback',
    confirmName: 'confirmReqSalesFeedback',
    confirmButtonText: 'cat._prompt_box._curtail_req_sales_feedback._ok',
    commentsMaxLength: 2000
  },
  API_ERROR: {
    header: 'cat._prompt_box._api_error._header',
    message: 'cat._prompt_box._api_error._message',
    confirmId: 'reqSalesFeedbackbtnOk',
    promptId: 'promptCurtailReqSalesFeedback',
    confirmName: 'confirmReqSalesFeedback',
    confirmButtonText: 'cat._prompt_box._api_error._ok',
  },
  CURTAIL_REQ_SALES_FEEDBACK_COMMENT: {
    header: 'cat._sales_feedback_comment._header',
    cancelButtonText: 'cat._sales_feedback_comment._cancelButtonText',
    confirmButtonText: 'cat._sales_feedback_comment._confirmButtonText',
    commentsMaxLength: 2000
  },
  UNLIMTED_PRODUCT: {
    header: 'cat._prompt_box.unlimited_product._header',
    message: 'cat._prompt_box.unlimited_product._message',
    promptId: 'unlimited-product-selection-prompt',
    confirmName: 'unlimited-product-selection-confirm',
    confirmId: 'unlimited-product-selection-confirm',
    confirmButtonText: 'cat._prompt_box.unlimited_product._yes',
    cancelId: 'unlimited-product-selection-cancel',
    cancelName: 'unlimited-product-selection-cancel',
    cancelButtonText: 'cat._prompt_box.unlimited_product._no'
  },
  CANCEL_RENEGOTIATION: {
    header: 'cat._prompt_box._cancel_renegotiation._header',
    message: 'cat._prompt_box._cancel_renegotiation._message',
    promptId: 'cancel-renegotiation-confirmation-prompt',
    confirmName: 'cancel-renegotiation-confirmation-confirm-button',
    confirmId: 'cancel-renegotiation-confirmation-confirm',
    confirmButtonText: 'cat._prompt_box._cancel_renegotiation._yes',
    cancelId: 'cancel-renegotiation-confirmation-cancel',
    cancelName: 'cancel-renegotiation-confirmation-cancel-button',
    cancelButtonText: 'cat._prompt_box._cancel_renegotiation._no'
  }

};

export const releaseUpdateTypes = {
  RELEASE_NOTE: 'Release note',
  APPLICATION_UPDATE: 'Application update',
  DOWNTIME: 'Downtime'
};

export const complianceChartConst = {
  type: 'column',
  chartBorderColor: '#979797',
  title: 'Compliance breakdown',
  xAxisTitle: 'Week',
  yAxisTitle: 'FFE',
  pointWidth: 10,
  pointPadding: 0,
  groupPadding: 0,
  chartWidth: 900,
  seriesReqFFEName: 'Committed',
  seriesReqFFEColor: '#003E5E',
  seriesConsumedFFEName: 'Consumed',
  seriesOverperformanceFFEName: 'Overperformance',
  seriesConsumedFFEColor: '#69B8D6',
  seriesOverperformanceFFEColor: '#008000',
  maxPerformanceLimit: 100,
  highestPerformanceLimit: 90,
  highPerformanceLimit: 80,
  mediumPerformanceLimit: 60,
  lowPerformanceLimit: 0,
  extraPerformanceBackgroundColor: '#417505',
  extraPerformancePerformanceColor: '#FFFFFF',
  highestPerformanceBackgroundColor: '#417505',
  highestPerformancePerformanceColor: '#FFFFFF',
  highPerformanceBackgroundColor: '#B8E986',
  highPerformancePerformanceColor: '#4A4A4A',
  mediumPerformanceBackgroundColor: '#F5A623',
  mediumPerformanceColor: '#4A4A4A',
  lowPerformanceBackgroundColor: '#FF0000',
  lowPerformanceColor: '#FFFFFF',
  footerFormat: '</table>',
  headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
  pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
    '<td style="padding:0"><b>{point.y} FFE</b></td></tr>',
  xAxisAlternateGridColor: '#F2F2F2',
  plotBandBorderColor: '#3F3C3B',
  plotBandBorderWidth: 1,
  plotBandLabelText: 'Current Week',
  plotBandLabelTextAlign: 'center',
  plotBandLabelTextVerticalAlign: 'top',
  plotBandLabelYAxisOffset: -10,
  plotOptionsColumnStacking: 'normal',
  graphOffset: 80,
  legendBorderWidth: 1,
  legendItemHoverColor: '#FF0000',
  legendTitleText: 'Show allocations <span style="font-size: 9px; color: #666; font-weight: normal">(Click to hide)</span>',
  maxColumnCount: 12
};

export const customReasonCode = {
  accept_renegotiation: 'ACCEPT_RENEGOTIATION',
  approve_pending: 'APPROVE',
  accept_counter: 'ACCEPT_COUNTER'
};

export const createRequestQueryParams = {
  brand: 'brand',
  scv: 'scv',
  route: 'route',
  cargo: 'cargo',
  nac: 'nac',
  nacMaxLength: 100,
  startDate: 'startDate',
  endDate: 'endDate',
  product: 'product'
};

export const unlimitedRequestWarningMsgStatus = ['PENDING'];

export const curtailmentType = {
  byStdFormula: 'S',
  byPercent: 'P',
  byFfe: 'F'
};

export const roleRestrictedUriMapping = [
  { role: 'RevenueManager', uri: [] },
  { role: 'TradeManager', uri: [] },
  { role: 'SalesAgent', uri: ['requestCurtailment/curtail'] },
  { role: 'GSC', uri: [] }
];

export const statusToExcludeForHAS = ['COUNTER_OFFERED', 'CURTAILMENT'];
export const HTTP_ERROR_CODES = {
  BAD_REQUEST: 400
};
export const exceptionModalConst = {
  height: 150,
  heightOffset: 200
};

export const curtailedInLast4WeeksFilterOptionsList: KeyValueModel[] = [ // TODO: needs to be removed after CA reference API added values for these filters
  { key: 'All', value: 'All' },
  { key: 'Yes', value: 'Yes' },
  { key: 'No', value: 'No' }
];

export const actionTakenOptionsList: KeyValueModel[] = [ // TODO: needs to be removed after CA reference API added values for these filters
  { key: 'All', value: 'All' },
  { key: 'Action Performed', value: 'Action performed' },
  { key: 'Action Pending', value: 'Action pending' },
  { key: 'No Action Required', value: 'No action required' }
];

export const validityTypeOptionsList: KeyValueModel[] = [ // TODO: needs to be removed after CA reference API added values for these filters
  { key: 'All', value: 'All' },
  { key: 'Short term', value: 'Short' },
  { key: 'Long term', value: 'Long' },
  { key: 'None', value: 'None' }
];

export const sporadicFilingOptionsList: KeyValueModel[] = [ // TODO: needs to be removed after CA reference API added values for these filters
  { key: 'All', value: 'All' },
  { key: 'Yes', value: 'Yes' },
  { key: 'No', value: 'No' }
];

export const odAllocationTypeOptionsList: KeyValueModel[] = [
  { key: 'All', value: 'All' },
  { key: 'ODA', value: 'Origin destination pairs' },
  { key: 'MOD', value: 'Multiple origins & destinations' }
];

export const filingMethodsForProducts: KeyAliasValueModel[] = [
  /*{key: 'STEERED', alias: 'SSA', value: 'cat._create_od.labels._steered_service'},*/
  { key: 'SINGLE_OD', alias: 'ODA', value: 'cat._create_od.labels._origin_destination_pairs' },
  { key: 'MULTIPLE_OD', alias: 'MOD', value: 'cat._create_od.labels._multiple_origins_destinations' }
];

export const contractType = {
  shortTerm: 'Short term',
  longTerm: 'Long term'
};

export const curtailmentValueLimit = {
  byFfe: 10000,
  byPercent: 100
};
export const screenToRetailFilter = {
  pendingTray: 'pendingTray',
  curtailment: 'curtailment'
};

export const sessionObject = {
  pendingTraySession: 'retainPendingTraySession',
  curtailmentSession: 'retainCurtailmentSession'
};
export const allowedCustomerForUnlimited = ['BCO', 'KEYC'];

export const monthlyComplianceNAStatuses = ['REJECTED', 'EXPIRED', 'CANCELLED', 'PENDING', 'COUNTER_OFFERED'];

export const brandCarrierCode = {
  MSL: 'MAEU',
  SCL: 'SAFM',
  SGL: 'SEJJ',
  SEA: 'SEAU',
  MCC: 'MCPU'
};

export const validityType = {
  shortTerm: 'SHORT TERM',
  longTerm: 'LONG TERM',
  short: 'Short',
  long: 'Long',
  none: 'None'
};

export const slideQuarterInViewModeStatuses = ['CONFIRMED', 'PROCESSING', 'CURTAILMENT'];

export const serviceContractWeeklyBreakdown = {
  maxQuarterInFrame: 4
};

export const rolesToEnableExceptionalWeeks = { role: ['TradeManager', 'RevenueManager'] };

export const odReqStatusesEligibleToDisplayGraphLink = ['CONFIRMED', 'RENEGOTIATION', 'EXPIRED', 'CANCELLED'];

export const odReqStatusesEligibleForAccelerate = ['CONFIRMED', 'RENEGOTIATION', 'EXPIRED', 'CANCELLED'];
export const odReqStatusesEligibleForCreateAccelerate = ['CONFIRMED'];

export const odConsumptionFieldsVisibility = ['CONFIRMED', 'RENEGOTIATION', 'EXPIRED', 'CANCELLED'];

export const allocationVariantOldValuesList = {
  twoWayCommitment: '2-way commitment',
  additionalAllocation: 'Additional allocation'
};

export const allocationVariantNewValuesList = {
  noVariants: 'No variants',
  bothVariants: 'Both variants',
  only2wayCommitment: 'Only 2-way commitment',
  onlyAdditionalAllocation: 'Only Additional allocation',
  exclude2WayCommitment: 'Exclude 2-way commitment',
  excludeAdditionalAllocation: 'Exclude Additional allocation'
};

export const filingMethods = {
  single_od: 'single_od',
  multiple_od: 'multiple_od'
};

export const allocationType = {
  singleOd: 'ODA',
  multipleOd: 'MOD',
};

export const equipmentSize = {
  SMALL: "20FT",
  LARGE: "40FT",
  NA: "NA"
}

export const numberOfWeeksForOdValidation = 4;

export const responseCodes = {
  requestTimeOut: 408
}

export const weeklyComponentTypeList : KeyValueModel[] = [
  { key: 'allocationVolumes', value: 'Customer Allocation'},
  { key: 'accelerateVolumes', value : 'Accelerate' }
];

export const productApplicableForCurtailment = ['BasicAlloc', 'SeasonalAlloc', 'SeasonalPresetAlloc', 'SeasonalSyncAlloc'];
export const productApplicableForHASInPendingTray = ['BasicAlloc', 'SeasonalAlloc', 'SeasonalPresetAlloc', 'SeasonalSyncAlloc', 'SporadicAlloc'];
