import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HttpClientModule} from '@angular/common/http';
import {LogoutComponent} from './eager-loaded/logout/logout.component';


const routes: Routes = [
  {path: '', pathMatch: 'full', redirectTo: 'home'},
  {path: 'customerAllocation', loadChildren: () => import('./ca-request-creation/ca-request-creation.module').then(m => m.CaRequestCreationModule)},
  {
    path: 'pendingTray',
    loadChildren: () => import('./ca-request-pending-tray/ca-request-pending-tray.module').then(m => m.CaRequestPendingTrayModule),
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'requestDetails/:id',
    loadChildren: () => import('./ca-request-details-view/ca-request-details-view.module').then(m => m.CaRequestDetailsViewModule),
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'customerAllocationConfirmation',
    loadChildren: () => import('./customer-allocation-agreement-form-response/customer-allocation-agreement-form-response.module').then(m => m.CustomerAllocationAgreementFormResponseModule)
  },
  {path: 'logout', component: LogoutComponent},
  {path: 'home', loadChildren: () => import('./ca-home/ca-home.module').then(m => m.CaHomeModule)},
  {path: 'invalid', loadChildren: () => import('./invalid-url-detail/invalid-url-detail.module').then(m => m.InvalidUrlDetailModule)},
  {path: 'allocations', loadChildren: () => import('./ca-request-od/ca-request-od.module').then(m => m.CaRequestOdModule)},
  {
    path: 'odDetails/:id',
    loadChildren: () => import('./ca-request-details-od/ca-request-details-od.module').then(m => m.CaRequestDetailsOdModule),
    runGuardsAndResolvers: 'always'
  },
  {path: 'requestCurtailment', loadChildren: () => import('./request-curtailment/request-curtailment.module').then(m => m.RequestCurtailmentModule)}
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
    HttpClientModule
  ],
  declarations: [],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule {

}
