import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {UserRole} from './user-role.model';
import {environment} from '../../environments/environment';
import {environmentEndpoints} from '../../environments/environment.endpoints';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

const {api_cat_gw_url} = environment;
const {rolePermission,api_ca_reference_root} = environmentEndpoints;

@Injectable()
export class PermissionService {

  // URL to load permission
  permissionsUrl: string = `${api_cat_gw_url}${api_ca_reference_root}${rolePermission}`;

  constructor(private http: HttpClient) {
  }

  /**
   * Method to prepare permissions
   */
  loadPermissions(): Observable<UserRole[]> {
    return this.http.get<UserRole[]>(this.permissionsUrl).
    pipe(map((response) => response),
    catchError(this.errorHandler));
    
  }

  /**
   * errorHandler() - Method to handle errors in the services
   * @param error
   */
  errorHandler(error: Response | any) {
    return of(error);
  }

}
