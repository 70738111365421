
import { Injectable } from '@angular/core';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { LoadReferenceData, ReferenceDataActionTypes, ReferenceDataError, ReferenceDataLoadComplete } from '../actions/reference-data.actions';
import {of} from 'rxjs';
import { FilterService } from '../../common-services/filter.service';
import { createEffect, Actions, ofType } from '@ngrx/effects';

@Injectable()
export class ReferenceDataEffects {

  constructor(private actions$: Actions, private filterService: FilterService) { }
  
  
  loadReferenceData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<LoadReferenceData>(ReferenceDataActionTypes.LoadReferenceData),
      mergeMap((action) => this.filterService.loadCarFilter()
        .pipe(
          map( referenceData => {
            return (new ReferenceDataLoadComplete({ referenceData: referenceData }));
          }),
          catchError((errorMessage) => of(new ReferenceDataError({ error: errorMessage })))
        ))
    );
        });

}
