import {Directive, ElementRef, EventEmitter, HostListener, Input, Output} from '@angular/core';

@Directive({
  selector: '[appRegExpRestrictedChars]'
})
export class RegExpRestrictedDirective {

  @Input() regExpPattern: string;
  @Output() ngModelChange = new EventEmitter();

  constructor(private el: ElementRef) {
  }

  @HostListener('keyup') keyup() {
    this.findAndReplaceTheRestrictedChars(this.regExpPattern);
  }

  @HostListener('keydown') keydown() {
    this.findAndReplaceTheRestrictedChars(this.regExpPattern);
  }

  /**
   * findAndReplaceTheRestrictedChars() - Method to validate the regExpression pattern and replace with wmpty string if the match found
   * @param pattern
   */
  private findAndReplaceTheRestrictedChars(pattern: string) {
    if (this.el.nativeElement.value) {
      this.el.nativeElement.value = this.el.nativeElement.value?.replace(pattern, '');
      this.ngModelChange.emit(this.el.nativeElement.value);
    }
  }
}
