export class CustomerAllocationAgreementValidationModel {
  value: any;
  erroMsgKey: string;
  regex: any;
  regexMsg: string;
  minLength: number;
  minLengthMsg: string;
  maxLength: number;
  maxLengthMsg: string;
  minValue: number;
  minValueMsg: string;
  maxValue: number;
  maxValueMsg: string;
  isUndefinedAllowed: boolean;
  mandatoryMsg: string;
}

export class CustomerAllocationAgreementValidationResponseModel {
  isValid = true;
  erroMsgKey: string;
  errorMessageValue: string;
  errorMessageAdditionalValue: string;
}
