import { Action } from '@ngrx/store';
import { Theme } from '../../themes/theme';

export enum BrandingActionTypes {
  LoadBranding = '[Branding] Load Branding',
  BrandingError = '[Branding] Branding Error'
}

export class BrandingAction implements Action {
  type: string;
  payload: {
    branding: Theme,
    features: string[]
    error: string
  };
}

export class LoadBranding implements Action {
  readonly type = BrandingActionTypes.LoadBranding;

  constructor(readonly payload: { branding: Theme, features: string[] }) {
  }
}

export class BrandingError implements Action {
  readonly type = BrandingActionTypes.BrandingError;

  constructor(readonly payload: { error: string }) {

  }
}

export type BrandingActions = LoadBranding | BrandingError;