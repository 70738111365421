import { UserRoleService } from '../authorization/user-role.service';
import { Store } from '@ngrx/store';
import { Component, OnInit } from '@angular/core';

import { UtilityService } from '../common-services/utility.service';
import { environmentEndpoints } from '../../environments/environment.endpoints';
import { AppState, features } from '../ngrx/state/index';
import { select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { DomainService } from '../authorization/domain.service';
import { applicationProperties } from '../../application-properties';
import { domainUrlRegExp } from '../../environments/domain';

const { imgURL } = environmentEndpoints;

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {

  userRoleList: string[] = [];
  menuState = {
    DEFAULT: 'default',
    HOVER: 'hover',
    SELECTED: 'selected'
  };

  features$: Observable<string[]>;
  features: string[];
  menuItems = [
    {
      icon: imgURL + 'home',
      state: 'default',
      link: '/home',
      label: 'HOME',
      id: 'home',
      active: false,
      menuName: 'HOME',
      permission: 'ViewAllocation',
      display: false
    },
    {
      icon: imgURL + 'create',
      state: 'default',
      link: '/customerAllocation/create',
      id: 'create',
      label: 'CREATE',
      active: false,
      menuName: 'CREATE',
      permission: 'CreateDraftAllocation',
      display: false
    },
    {
      icon: imgURL + 'request',
      state: 'default',
      link: '/pendingTray',
      label: 'REQUESTS',
      id: 'requests',
      active: false,
      menuName: 'REQUESTS',
      permission: 'ViewAllocation',
      display: false
    },
    {
      icon: imgURL + 'create',
      state: 'default',
      link: '/allocations/create',
      id: 'od-create',
      label: 'CREATE OD',
      active: false,
      menuName: 'OD',
      permission: 'ODPilotUser',
      display: false
    },
    {
      icon: imgURL + 'request',
      state: 'default',
      link: '/requestCurtailment/curtail',
      label: 'CURTAIL',
      id: 'curtailment',
      active: false,
      menuName: 'CURTAIL',
      permission: 'ViewCurtailment',
      display: false
    },
  ];

  constructor(private utilityService: UtilityService, 
    private store: Store<AppState>,
    private domainService: DomainService,
    private userRoleService: UserRoleService
  ) {
    this.features$ = this.store.pipe(select(features));
  }

  ngOnInit() {
    this.features$.subscribe(res => {
      this.features = res;
      this.initializeMenuItems();
    });

    this.userRoleService.userRoles.subscribe(roles => {
      this.userRoleList = roles
        .filter(role => role.roleName !== applicationProperties.guestUser)
        .map(role => role.roleName);
      if (this.userRoleList && this.userRoleList.length === 0 && 
        this.domainService.currentDomain.match(domainUrlRegExp.HAS)){
        for (let i = 0; i < this.menuItems.length; i++) {
          this.menuItems[i].display = false;
        }
      }
    });
  }

  /**
   * isActive() - Method to check which route is currently Active
   * @param menu
   */
  isActive(menu): boolean {
    if (this.utilityService.isActiveRoute(menu.link)) {
      menu.state = this.menuState.SELECTED;
      return true;
    }
    if (menu.state !== this.menuState.HOVER) {
      menu.state = this.menuState.DEFAULT;
    }
    return false;
  }

  /**
   * onMouseOver() - Method to handle Hover state on the Menu Icons
   * @param menu
   */
  onMouseOver(menu) {
    menu.state = this.menuState.HOVER;
  }

  /**
   * onMouseOut() - Method to handle onBlur state of Menu Icons
   * @param menu
   */
  onMouseOut(menu) {
    menu.state = this.menuState.DEFAULT;
  }

  initializeMenuItems() {
    for (let i = 0; i < this.menuItems.length; i++) {
      this.menuItems[i].display = this.features.includes(this.menuItems[i].menuName);
    }
  }

}
