import {Injectable} from '@angular/core';
import * as dayjs from 'dayjs';
import {CatDateFormatPipe} from '../modules/utils/pipe/cat-date-format.pipe';
import {seasonalityConst} from '../shared-module/cat-constant';
import {CAT_DATE_FORMAT} from '../customer-allocation-agreement-date-formats';

@Injectable()
export class StringUtilsService {

  /**
   * isNumber() - Method to check if value is a number
   * @param n
   */
  isNumber(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
  }

  /**
   * getCatDate() - Method to return date specific to CAT
   */
  getCatDate(inputDate: dayjs.Dayjs): string {
    const catDateFormatPipe: CatDateFormatPipe = new CatDateFormatPipe();
    return catDateFormatPipe.transform(inputDate);
  }

  /**
   * This method will calculate and return the number of weeks between start and end dates
   * @param startDate
   * @param endDate
   */
  getNumberOfWeeks(startDate: dayjs.Dayjs, endDate: dayjs.Dayjs): number {
    let weekStartDate: dayjs.Dayjs;
    let weekCount: number;
    weekStartDate = startDate.clone();
    weekStartDate.date(weekStartDate.date() - (weekStartDate.get('day') - 1));
    weekCount = endDate.diff(weekStartDate, 'week') + 1;
    return weekCount;
  }

  /**
   * This method will format the input date field for AO routing service call
   * @param startDate
   */
  getDateForAoRouting(startDate: dayjs.Dayjs): string {
    return startDate.format(CAT_DATE_FORMAT.YYYYMMDD);
  }

  /**
   * This method will format the input date field for MEPC routing service call
   * @param startDate
   */
  getDateForMepcRouting(startDate: dayjs.Dayjs): string {
    return startDate.format(CAT_DATE_FORMAT.DDMMMYYYY);
  }

  /**
   * Function to get next monday date
   */
  getNextMonday() {
    const now = new Date();
    now.setDate(now.getDate() + (1 + 7 - now.getDay()) % 7);
    if (now.getUTCDate() === new Date().getUTCDate()) {
      now.setDate(now.getDate() + 7);
    }
    return dayjs(now).format(CAT_DATE_FORMAT.DDMMMYYYY);
  }

  /**
   * This method will take fractional number as input and apply the floor function to return a number x or x.5 or x+1
   * @param num
   * @returns number
   */
  floorNearestToPointFive(num: number): number {
    return Math.floor(num) + (Math.round((num - Math.floor(num))) ? seasonalityConst.pointFive : seasonalityConst.pointZero);
  }

  /**
   * This method will calculate and return the percentage of input number.
   * @param num
   * @param percent
   * @returns number
   */
  percentageIncrementDecrement(num: number, percent: number): number {
    return ((num * percent) / 100);
  }

  /**
   * This function returns concatenation of 'key' and 'value' with the "baseUrl"
   * @param baseUrl
   * @param key
   * @param value
   */
  returnConcatenatedUrl(baseUrl: string, key: string, value: string): string {
    if (baseUrl && value) {
      return `${baseUrl}&${key}=${value}`;
    } else {
      return baseUrl;
    }
  }

  /**
   * Funtion to compare if two objects are equal
   */
  areEqual(obj1: any, obj2: any): boolean {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  }

}