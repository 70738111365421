// The file contains all the common application url endpoints for each environment file.
//

export const environmentEndpoints = {

  langRootURL: '/assets/i18n/',
  imgURL: '/assets/img/',

  /**
   * Endpoints corresponding to CAT Service URL
   */
  customSearch: 'search/',                                                //
  allocations: 'allocations',                                             //
  aflsScvSearchUrl: 'servicecontracts/scvcode/',                          // endpoint for AFLS search with SCV code
  aflsAgreementSearchUrl: 'servicecontracts/',                            // endpoint for AFLS search with Agreement Number
  download_excel_api: 'allocations/download/xlsx/weeklydistribution',     // endpoint for download excel
  validateCARequestManuallyUrl: 'validateCARequestManually',              // endpoint for manual request validation
  downloadRequestDeatilsURL: 'download/xlsx/requests',                    // endpoint for download excel
  downloadODRequestDeatilsURL: 'downloadODExcel',                         // endpoint for OD download excel
  weeklyConsumptionUrl: 'consumption/weekly',                             // endpoint for YVD consumption
  addEmailAddress: 'emailaddress/add',                                    // endpoint for add additional email
  removeEmailAddress: 'emailaddress/remove',                              // endpoint for remove additional email
  odAddEmailAddress: 'emailaddress/add',                                  // endpoint for OD add additional email
  odRemoveEmailAddress: 'emailaddress/remove',                            // endpoint for OD remove additional email
  odAddResponsibleSalesEmailAddress: 'salesAgent/add',                    // endpoint for OD update sales responsible email
  odUpdateResponsibleSalesEmailAddress: 'salesAgent/update',              // endpoint for OD update sales responsible email
  fetchAuditTrailUrl: 'history',                                          // endpoint for audit history
  odRequestEdit: '/customerAllocation/od/edit',                           // endpoint for OD edit
  steeredRequestEdit: '/customerAllocation/edit',                         // endpoint for steered edit
  endDateReduce:'/confirmedBulkEdit/endWeek',                                                       // endpoint for OD reduce validity period
  odView: '/odDetails/',                                                  // endpoint for OD view
  steeredView: '/requestDetails/',                                        // endpoint for steered view
  salesFeedback: '/salesFeedback',                                        // endpoint for salesFeedback
  curtailment: '/curtailment',                                            // endpoint for curtailment get request
  curtail: '/curtailment/submit',                                         // endpoint for curtailment request submission
  add_responsible_sales_email: 'salesAgent/add',                          // endpoint for add sales responsible email
  update_responsible_sales_email: 'salesAgent/update',                    // endpoint for updating sales responsible email
  request_sales_feedback: '/salesFeedback/requestFeedback',               // to request feedback from sales users
  get_curtailment_requests: '/getCurtailmentRequests',                    // get filtered curtailment request data
  monthlyCompliance: '/monthlyCompliance',                                // endpoint for monthly compliance data

  // Endpoints for OD Allocations
  create: 'create',

  /**
   * Endpoints corresponding to CA REF URL
   */
  tool_reference: 'tool',                                               // endpoint for
  week_dist_reference: 'week?',                                         // endpoint for
  quarterly_weeks: 'allweek?',                                          // endpoint for quarterly weeks data
  rolePermission: 'rolePermission',                                     // endpoint for fetching user role-permission
  userClusterInfo: 'user/cluster',                                      // endpoint for fetching cluster info
  filterPreference: 'filterPreference',                                 // endpoint for user filter preference
  weekNumberInfo: 'weeknumber',
  releaseNotesURL: 'release',                                           // endpoint for release note data
  downtimeURL: 'downtimeInfo',
  notesConfig: 'config/',
  productType: 'products/all/details',                                  // endpoint for fetching product details from PMA
  cmdUrlByCode: 'customer/',                                            // endpoint for cmd rest api search by customer code
  cmdUrlByName: 'customers/',                                           // endpoint for cmd rest api search by customer name
  approve: 'approve',                                                   // endpoint to approve OD request
  reject: 'reject',                                                     // endpoint to reject OD request
  canceldraftorpending: 'canceldraftorpending',                         // endpoint to cancel draft or pending OD request
  cancelconfirmed: 'cancelconfirmed',                                   // endpoint to cancel confirmed OD request
  patchSteeredService: 'patchSteeredService',                           // endpoint to re-trigger the enrich steered service in database
  renegotiate: 'renegotiate',
  getDerivedContracts: 'getDerivedContracts',
  updateConfirmedRequest: 'update/confirmed',
  editRenegotiation: 'edit/renegotiate',
  editConfirmed: 'edit/confirmed',
  confirmCoRequest: 'counteroffer/accept',
  rejectCoRequest: 'counteroffer/reject',
  counterOffer: 'counteroffer',
  // endpoint to accept or reject renegotiation
  acceptRenegotiation: 'renegotiate/accept',
  rejectRenegotiation: 'renegotiate/reject',
  cancelRenegotiation: 'renegotiate/cancel',
  editAndAcceptRenegotiation: 'renegotiate/editandaccept',
  editPending: 'edit/pending',
  // api url for home page feedback
  feedback: 'feedback/CAT/feedbackEmail',
  hsudFeedBack: 'feedback/HAS/feedbackEmail',
  allocationLevel: 'allocationlevel',
  allProductRule: 'productrule/all',
  quarterWeeksMapping: 'allweeks?',                                          // endpoint for quarterly weeks data

  // api url for home page video link
  video_url: 'https://teamsite.maerskgroup.com/teams/FutureProduct/SitePages/Global-Roll-Out--Customer-Allocation-Tool.aspx',

  has_video_url: 'https://teamsite.maerskgroup.com/teams/CAT901/Shared%20Documents/Forms/AllItems.aspx?RootFolder=%2Fteams%2FCAT901%2FShared%20Documents%2FGeneral&FolderCTID=0x012000DB11A1E2C3AB69409E9015A9B8D4DCDE',

  // Customer Allocation Visibility application
  cav_url: 'https://fcp-customer-allocation-limits-ui.maersk-digital.net/',

  // Product Types detail URL
  product_type_detail_url: 'https://teamsite.maerskgroup.com/teams/FutureProduct/Allocation Products/Contract Products 2019 Refresher.pdf',

  // Raise access request in Service Now
  access_request_service_now_url: 'https://maersk.service-now.com/end_user_portal?id=sc_cat_item&sys_id=731aa23ddb0da3c02dad303c7c961948&table=sc_cat_item',
  has_access_request_service_now_url: 'https://maersk.service-now.com/end_user_portal?id=sc_cat_item&sys_id=4ac0e481db30a70495bcf05c0c9619c4&table=sc_cat_item',

  // Raise an incident in Service Now
  incident_log_service_now_url: 'https://maersk.service-now.com/end_user_portal?id=sc_cat_item&sys_id=4ac0e481db30a70495bcf05c0c9619c4&table=sc_cat_item',

  // CAT error resolution guide book
  cat_error_resolution_guide: 'https://teamsite.maerskgroup.com/:w:/r/teams/FutureProduct/_layouts/15/Doc.aspx?sourcedoc=%7BA8596131-00D9-4A28-BCB6-DF313C0ED044%7D&file=CAT%20error%20resolution%20guide%20v2.docx&action=default&mobileredirect=true',

  // api url for AO route service
  ao_route_service_api: 'https://api.maerskline.com/maeu/products/futureschedules',

  // api url for MEPC service
  mepc_api_endpoint: 'routedirection',

  // api urls for vas
  api_vas_root: 'odvas/',
  api_vas_accelerate: 'accelerate',
  api_vas_pendingTray: 'pendingTray',
  api_vas_accelerateApporve: 'approve',
  api_vas_accelerateReject: 'reject',
  api_vas_accelerateCancelConfirmed: 'cancel/confirm',
  api_vas_accelerateEditConfirmed: 'edit/confirmed',
  api_vas_accelerateCounterOffer: 'counteroffer',
  api_vas_accelerateRejectCounterOffer: 'counteroffer/reject',
  api_vas_accelerateAcceptCounterOffer: 'counteroffer/accept',

  //flexhub
  api_flexhub: 'allocations',
  api_flexhub_parent:'parent/',
  api_flexhub_confirm: 'confirm',
  api_flexhub_reject: 'reject',

  // Api od groups
  api_origindestinationgrouping: 'origindestinationgrouping/',

  // API steered service
  api_steerservice: 'steeredServices/',

  // API OD
  api_od_root: 'odallocationrequest/v2/',

  // API OD compliance
  api_od_compliance_root: 'odcompliance/v2/allocation/',

  // API notification
  api_notification_root: 'notifications/v3/',

  // API od_pair_validation
  api_od_pair_validation_root: `routedirectionservice/v2/validate`,

  // API exbproxy cmd
  api_esb_proxy_cmd_root: `esbproxy/cmd/v1/`,

  // API exbproxy afls
  api_esb_proxy_afls_root: `esbproxy/afls/v1/`,

  // API exbproxy mepc
  api_esb_proxy_mepc_root: `esbproxy/mepc/v1/`,

  // API ca_reference
  api_ca_reference_root: 'referencedata/v1/',

  // API contractDerivedValidity
  api_contractDerivedValidity_root: 'contractderivedvalidity/v1/',
  
  // API cat_steered_service
  api_cat_ss_root: 'allocationrequest/v2/',

  //API cat_accelerate_api
  api_accelerate_bulkEdit: 'updateReducedVolumes',

  //API cat_accelerate_automation_payload_api
  api_accelerate_automation: 'reducedWeeklyVolumes',

  // Azure Configuration
  msalConfig: {
    prodEnvt: {
      clientId: '45c772bb-b60c-4345-b234-32e20042650a',
    },
    nonProdEnvt: {
      clientId: 'ed9a3900-92a6-41a4-b7ed-3334231b26a5',
    },
    tenant: '05d75c05-fa1a-42e7-9cf1-eb416c396f2d',
    cacheLocation: 'localStorage'
  },
  dataDogRUMConfig: {
    applicationId: 'c1882eed-4f10-4821-8c43-4647b6bd88a3',
    clientToken: 'pub45e154d72ac902a7a0719ae70728dc4e',
    site: 'datadoghq.eu',
    service: 'customer-allocation-tool',
    env: 'bdd',
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sampleRate: 100,
    trackInteractions: true
  },
  dataDogProdRUMConfig: {
    applicationId: '62469668-9a68-4191-ada6-586f7787e0b5',
    clientToken: 'pub6cf9e40f307ea3f0435f68a651f4c1bb',
    site: 'datadoghq.eu',
    service:'customer-allocation-tool-(prod)',
    env:'prod',
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sampleRate: 100,
    trackInteractions: true
  }
};
