import { Injectable } from '@angular/core';
import { roleRestrictedUriMapping } from '../shared-module/cat-constant';
import { applicationConstants } from '../common-services/constants.service';
import { domainUrlRegExp } from '../../environments/domain';
import { DomainService } from './domain.service';
import { MsalService } from '@azure/msal-angular';

@Injectable()
export class PageAccessValidatorService {

  domainUrl: string;
  roles: string[] = [];
  brands: string[] = [];

  constructor(
    private domainService: DomainService,
    private msalService: MsalService) {
    this.domainUrl = this.domainService.currentDomain;
  }

  validateUserAccess(): boolean {

    
    let roles : string[];
    if(this.msalService.instance && this.msalService.instance.getActiveAccount()){
      roles = this.msalService.instance.getActiveAccount().idTokenClaims['roles'];
    }    
    
    if (roles) {
      this.roles = roles.filter(role => applicationConstants.userRoles.includes(role));
      this.brands = roles.filter(role => !applicationConstants.userRoles.includes(role));
    }

    // check if user have any role. If not and he click to view draft allocation return true.
    if (this.roles.length === 0 && !this.domainUrl.match(domainUrlRegExp.HAS) &&
      (window.location.href.includes('pendingTray') ||
        window.location.href.includes('requestDetails') ||
        window.location.href === (`${window.location.origin}/`) ||
        window.location.href === window.location.origin ||
        window.location.href.includes('home'))
    ) {
      return true;
    }
    // check if user have any role. If not return false to identify it as a guest user.
    if (!roles) {
      return false;
    }

    // For HamburgSud domain if it does not have access to HamburgSud Brand return false
    if (this.domainUrl.match(domainUrlRegExp.HAS) &&
      !this.brands.includes(applicationConstants.brandsToExclude)) {
      return false;
    }

    // Validation for sidebar tab specific menu based on user role
    // for example only 'TradeManager' can see the 'CURTAILMENT' menu on side bar.
    // If user with a specific role tries to directly access a page by changing url, but does not has permission for that page
    // then that is validated here
    if (this.roles) {
      return this.validateUrlForRole(this.roles, window.location.href);
    }
    return true;
  }

  /**
   * This is called from 'validateUserAccess()' function.
   * It validates the input 'roles' and 'uriToBeValidated' against the predefined list of role and uri mapping.
   * @param roles
   * @param uriToBeValidated
   */
  validateUrlForRole(roles: string[], uriToBeValidated: string): boolean {
    const isAccessAllowed: boolean[] = [];
    roles.forEach(role => {
      roleRestrictedUriMapping.forEach(mapping => {
        if (role === mapping.role) {
          mapping.uri.forEach(uri => {
            if (uriToBeValidated.includes(uri)) {
              isAccessAllowed.push(false);
            }
          });
        }
      });
    });
    return (isAccessAllowed.length !== roles.length);
  }

}
