import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class DomainService {

  currentDomain;

  constructor(@Inject(DOCUMENT) private document: Document) {
    this.currentDomain = this.document.baseURI;
   }

}
