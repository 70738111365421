import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {HttpClient} from '@angular/common/http';

import {TileComponent} from '../user-controls/tile/tile.component';
import {LogoutComponent} from './logout/logout.component';
import {RegExpRestrictedDirective} from './directives/regular-expressions.directive';
import {createTranslateLoader} from '../utils/utils-functions';

export const components = [
  TileComponent,
  LogoutComponent,
  RegExpRestrictedDirective
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule.forChild({loader: {provide: TranslateLoader, useFactory: (createTranslateLoader), deps: [HttpClient]}})
  ],
  declarations: [...components],
  exports: [
    ...components
  ]
})
export class EagerLoadedModule {
}
