export const applicationProperties = {
    httpPost: "post",
    httpPut: "put",
    save: "save",
    submit: "submit",
    create: "create",
    editConfirmed: "editConfirmed",
    null: "null",
    dash: "-",
    space: " ",
    reefer: "REEFER",
    dry: "DRY",
    others: "Others",
    route: "route",
    confirmCaRequest: "approve",
    rejectCaRequest: "reject",
    createPendingCaRequest: "create/pendingallocation",
    createDraftCaRequest: "create/draftallocation",
    editPendingCaRequest: "edit",
    editPendingCaRequestEndpoint: "edit/pending",
    editDraftCaRequest: "edit/draft",
    submitDraftCaRequest: "edit/submitdraft",
    cancelDraftOrPendingCaRequest: "cancel/draftorpending",
    deleteDraftCaRequest: "delete/draft",
    cancelConfirmedCaRequest: "cancel/confirmed",
    confirmCoRequest: "counteroffer/accept",
    rejectCoRequest: "counteroffer/reject",
    counterOffer: 'counteroffer',
    requestRenegotiate: 'renegotiate',
    editRenegotiateRequest: 'editRenegotiate',
    editAndAcceptRenegotiateRequest: 'editAndAcceptRenegotiateRequest',
    editAndAcceptRenegotiateRequestUrl: 'renegotiate/editandaccept',
    acceptRequestRenegotiation: 'acceptRequestRenegotiation',
    acceptRequestRenegotiationUrl: 'renegotiate/accept',
    cancelRequestRenegotiationUrl: 'renegotiate/cancel',
    rejectRequestRenegotiation: 'renegotiate/reject',
    createReleaseVersion: 'release',
    createDowntimeNotification: 'downtimeInfo',
    updateConfirmed: 'updateConfirmed',
    editConfirmedEndpoint: 'edit/confirmed',
    updateRequestConfirmed: 'update/confirmed',
    editRenegotiateUrl: 'edit/renegotiate',
    confirm_add_email: 'emailaddress/add',
    keyc: 'KEYC',
    apply: 'apply',
    requestDetails: 'requestDetails',
    even: 'EVEN',
    uneven: 'UNEVEN',
    key: 'key',
    value: 'value',
    productionEnvironment: 'prod',
    guestUser: 'Guest',
    na: 'NA'
};
