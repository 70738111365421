import {Injectable} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';

import {MessageService} from '../messages/message.service';
import {UtilityService} from '../common-services/utility.service';
import {PageLoadMessageService} from '../common-services/page-load-message.service';
import {errorCode} from '../shared-module/cat-constant';

export const ERROR_CODE = {
  'CONNECTION_ERROR': '1001',
  'SERVER_ERROR': '1002',
  'WEEKLY_CAP_ERROR': 'Weekly volume should be less than maximum weekly volume 10 FFE',
  'NO_BRAND_ACCESS': 'User is not allowed to access this brand.'
};

@Injectable()
export class ExceptionService {

  constructor(public messageService: MessageService,
              private utilityService: UtilityService,
              private pageLoadMessageService: PageLoadMessageService) {
  }

  /**
   * processErrors() - Method to handle error caught during Service call
   * @param errorResponse
   */
  processErrors(errorResponse: Response | any) {
    if (errorResponse instanceof HttpErrorResponse) {
      switch (errorResponse.status) {
        case 0:
          this.addConnectionErrors(errorResponse);
          break;
        case 400:
          this.extractError(errorResponse);
          break;
        case 401:
          this.invalidSession();
          break;
        case 403:
          this.accessDenied(errorResponse);
          break;
        case 404:
          this.extractError(errorResponse);
          break;
        case 409:
          this.extractError(errorResponse);
          break;
        case 500:
          this.addGenericError(errorResponse);
          break;
        default:
          this.addGenericError(errorResponse);
      }
      this.pageLoadMessageService.changeMessage(false);
    }
  }

  /**
   * extractError() - Method to extract error from Error Response
   * @param errorResponse
   */
  extractError(errorResponse: Response | any) {
    this.messageService.clearErrors();
    if (errorResponse && errorResponse.error.invalid) {
      let errorMessage: string = '';
      if (errorResponse.error.invalid.length > 0) {
        if (errorResponse.error.invalid[0] === ERROR_CODE.WEEKLY_CAP_ERROR) {
          errorMessage = ERROR_CODE.WEEKLY_CAP_ERROR;
        } else if (errorResponse.error.invalid[0] === ERROR_CODE.NO_BRAND_ACCESS) {
          errorMessage = ERROR_CODE.NO_BRAND_ACCESS;
        } else {
          errorMessage = this.utilityService.loadTranslatedFieldDynamic('cat.exceptions._invalid_request', {param: errorResponse.error.invalid?.join(',')});
        }
        this.messageService.addErrors(errorMessage);
      }
    } else if (errorResponse && (errorResponse.error.code === errorCode.DUPLICATE001)) {
      this.messageService.duplicateErrorFound(errorResponse.error);
    } else if (errorResponse && (errorResponse.error.code === errorCode.DUPLICATE002)) {
      this.messageService.duplicateErrorFound(errorResponse.error);
    } else if (errorResponse && (errorResponse.error.code === errorCode.ERROR_PRODUCT_CHANGED)) {
      this.messageService.productErrorFound(errorResponse.error);
    } else if (errorResponse && errorResponse.error) {
      this.messageService.addErrors(errorResponse.error.message);
    }
  }

  /**
   * addGenericError() - Method to show geenric error on UI
   */
  addGenericError(errorResponse: Response | any) {
    this.messageService.addErrors(this.utilityService.loadTranslatedField('cat.exceptions._generic_error'));
  }

  /**
   * addConnectionErrors() - Check if there are connection errors from the back end services
   */
  addConnectionErrors(errorResponse: Response | any) {
    let errorMessage: string = `Error Code : ${ERROR_CODE.CONNECTION_ERROR}. ${this.utilityService.loadTranslatedField('cat.exceptions._connection_error')}`;
    this.messageService.addConnectionErrors(errorMessage);
  }

  /**
   * accessDenied() - Method to handle Forbidden Requests
   */
  accessDenied(errorResponse: Response | any) {
    this.messageService.addErrors(this.utilityService.loadTranslatedField('cat.exceptions._access_denied'));
  }

  invalidSession() {
    this.messageService.addErrors(this.utilityService.loadTranslatedField('cat.exceptions._invalid_session'));
  }
}
