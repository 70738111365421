import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

import {CustomerAllocationAgreementResponseModel} from './customer-allocation-agreement-response-model';


/**
 * It is a service class for data sharing between "CustomerAllocationAgreementComponent" and "CustomerAllocationConfirmationComponent".
 */
@Injectable()
export class CustomerAllocationMessageService {

  private messageSource = new BehaviorSubject<CustomerAllocationAgreementResponseModel>(new CustomerAllocationAgreementResponseModel());
  customerAllocationAgreementResponseModel = this.messageSource.asObservable();

  changeMessage(customerAllocationAgreementResponseModel: CustomerAllocationAgreementResponseModel) {
    this.messageSource.next(customerAllocationAgreementResponseModel);
  }

}
