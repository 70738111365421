import {Pipe, PipeTransform} from '@angular/core';
import * as dayjs from 'dayjs';
import {CAT_DATE_FORMAT} from '../../../customer-allocation-agreement-date-formats';

@Pipe({
  name: 'catDateFormat'
})
export class CatDateFormatPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (value) {
      return dayjs(value, CAT_DATE_FORMAT.DDMMMYYYY).format(CAT_DATE_FORMAT.DDMMMYYYY).toString();
    }
    return value;
  }

}
