
import { ReferenceDataActionTypes, ReferenceDataAction } from '../actions/reference-data.actions';
import { ReferenceDataState, initialReferenceDataState } from '../state/reference-data-state';

export function referenceDataReducer(state: ReferenceDataState = initialReferenceDataState, action: ReferenceDataAction): ReferenceDataState {
  switch (action.type) {
    case ReferenceDataActionTypes.ReferenceDataLoadComplete:
      return {
        ...state,
        carFilters: action.payload.referenceData,
        error: null
      };

    default:
      return state;
  }
}