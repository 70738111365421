import {EffectsModule} from '@ngrx/effects';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule} from '@angular/forms';
import {NgModule} from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {NgxPermissionsModule} from 'ngx-permissions';
import {AppRoutingModule} from './app-routing.module';
import {EagerLoadedModule} from './eager-loaded/eager-loaded.module';
import {AppComponent} from './app.component';
import {HeaderComponent} from './header/header.component';
import {FooterComponent} from './footer/footer.component';
import {SidebarComponent} from './sidebar/sidebar.component';
import {PermissionService} from './authorization/permission.service';
import {FeedbackService} from './user-controls/feedback/feedback.service';
import {AuthenticatorService} from './authorization/authenticator.service';
import {PageLoadMessageService} from './common-services/page-load-message.service';
import {UtilityService} from './common-services/utility.service';
import {PageScrollingService} from './common-services/page-scrolling.service';
import {ConstantsService} from './common-services/constants.service';
import {NavigationService} from './common-services/navigation.service';
import {MessageService} from './messages/message.service';
import {ExceptionService} from './exceptions/exception.service';
import {environment} from '../environments/environment';
import {ReleaseUpdatesService} from './common-services/release-updates.service';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSidenavModule } from '@angular/material/sidenav';
import {UserRoleService} from './authorization/user-role.service';
import {CatFormValidationService} from './common-services/cat-form-validation-services';
import {PageAccessValidatorService} from './authorization/page-access-validator.service';
import {StringUtilsService} from './utils/string-utils.service';
import {CustomerAllocationMessageService} from './customer-allocation-agreement/customer-allocation-message.service';
import {createTranslateLoader} from './utils/utils-functions';
import {ThemeModule} from './themes/theme.module';
import {StoreModule} from '@ngrx/store';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {metaReducers, reducers} from './ngrx/state/index';
import {ReferenceDataEffects} from './ngrx/effects/reference-data.effects';
import {FilterService} from './common-services/filter.service';
import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';
import { MsalBroadcastService, MsalInterceptor, MsalInterceptorConfiguration, MsalModule, MsalRedirectComponent, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG } from '@azure/msal-angular';
import { BrowserCacheLocation, InteractionType, IPublicClientApplication, LogLevel, PublicClientApplication } from '@azure/msal-browser';
import { NgxSpinnerModule } from 'ngx-spinner';
import { environmentEndpoints } from '../environments/environment.endpoints';

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1; // Remove this line to use Angular Universal

const envName = environment.envName;
const { skipAuthentication
  ,api_hsud_cmd,api_hsud_alfs,api_cat_gw_url,api_location_root,api_stowage_root,api_flexhub_root,api_accelerate_root } = environment;

export function loggerCallback(logLevel: LogLevel, message: string) {

}

export function MSALInstanceFactory(): IPublicClientApplication {
  let clientId="";
  if(envName==="prod"){
    clientId=environmentEndpoints.msalConfig.prodEnvt.clientId;
  }
  else{
    clientId=environmentEndpoints.msalConfig.nonProdEnvt.clientId;
  }
  return new PublicClientApplication({
      auth: {clientId:clientId,
      authority: 'https://login.microsoftonline.com/05d75c05-fa1a-42e7-9cf1-eb416c396f2d',
      redirectUri: '/',
      postLogoutRedirectUri: '/'
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11. Remove this line to use Angular Universal
    },
    system: {
      allowRedirectInIframe: true,
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false
      }
    }
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  let cat_api_scope: string = '';
  if(envName==="prod"){
    cat_api_scope = `${environmentEndpoints.msalConfig.prodEnvt.clientId}/.default`
  }
  else{
    cat_api_scope = `${environmentEndpoints.msalConfig.nonProdEnvt.clientId}/.default`
  }
  const protectedResourceMap = new Map<string, Array<string>>();
  if(!skipAuthentication){
    protectedResourceMap.set(api_hsud_cmd, [cat_api_scope]);
    protectedResourceMap.set(api_hsud_alfs, [cat_api_scope]);
    protectedResourceMap.set(api_cat_gw_url, [cat_api_scope]);
    protectedResourceMap.set(api_location_root, [cat_api_scope]);
    protectedResourceMap.set(api_stowage_root,[cat_api_scope]);
    protectedResourceMap.set(api_flexhub_root,[cat_api_scope]);
    protectedResourceMap.set(api_accelerate_root,[cat_api_scope]);
  }

  api_accelerate_root
  return {
    interactionType: InteractionType.Popup,
    protectedResourceMap
  };
}

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    ThemeModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    NgxPageScrollCoreModule.forRoot({scrollOffset: 100}),
    NgxPermissionsModule.forRoot(),
    AppRoutingModule,
    EagerLoadedModule,
    MatExpansionModule,
    MatSidenavModule,
    MatButtonModule,
    EffectsModule.forRoot([ReferenceDataEffects]),
    StoreModule.forRoot(reducers, {metaReducers}),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    MsalModule,
    NgxSpinnerModule
  ],
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
  ],
  providers: [
    FeedbackService,
    PermissionService,
    AuthenticatorService,
    PageLoadMessageService,
    UtilityService,
    StringUtilsService,
    CatFormValidationService,
    PageScrollingService,
    ConstantsService,
    NavigationService,
    MessageService,
    ReleaseUpdatesService,
    ExceptionService,
    UserRoleService,
    FilterService,
    PageAccessValidatorService,
    CustomerAllocationMessageService,
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    MsalService,
    MsalBroadcastService
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]  
})
export class AppModule {
  constructor() {}
}
