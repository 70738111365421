import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class PageLoadMessageService {

  private messageSource = new BehaviorSubject<boolean>(false);
  isPageLoading = this.messageSource.asObservable();

  changeMessage(isPageLoading: boolean) {
    this.messageSource.next(isPageLoading);
  }
}
