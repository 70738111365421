import {Inject, Injectable} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import { PageScrollService } from 'ngx-page-scroll-core';


@Injectable()
export class PageScrollingService {

  constructor(private pageScrollService: PageScrollService, @Inject(DOCUMENT) private document: any) {
  }

  /**
   * scrollPageToSpecificPosition() - Method to scroll to specific element
   * @param elementId
   */
  scrollPageToSpecificPosition(elementId) {
    let targetId = `#${elementId}`;
    this.pageScrollService.scroll({
      document: this.document,
      scrollTarget: targetId,
    });
  }

}
