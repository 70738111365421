import { Injectable } from '@angular/core';
import { Theme, HAS } from '../themes/theme';

@Injectable({
  providedIn: 'root'
})
export class FeatureConfigsService {

  defaultFeatures: string[] = ['HOME', 'CREATE', 'REQUESTS', 'OD', 'CURTAIL', 'CAV'];

  getEnabledFeature(theme: Theme): string[]{

    if(theme === HAS){
      return ['HOME', 'CREATE', 'REQUESTS'];
    }

    return this.defaultFeatures;
  }

}
