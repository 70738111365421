import {CAStatus} from '../common-models/customer-allocation-status-model';
import {CAReason} from '../common-models/customer-allocation-reason-model';
import {CAAdditionalEmailAddress} from '../common-models/customer-allocation-additional-email-address-model';

export class CustomerAllocationAgreementResponseModel {
  requestIds: string[];
  requestIdsForDisplay: string;
  groupId: number;
  creationDate: string;
  updatedDate: string;
  createdBy: string;
  updatedBy: string;
  status: CAStatus = new CAStatus();
  serverStatus: CAStatus = new CAStatus();
  responseToBeDisplayedOnNewPage = false;
  requestValidationStatus: string;
  labelText: string;
  displayCopyRequestTile: boolean = false;
  userInfoMessage: string;
  reason?: CAReason = new CAReason();
  additionalEmailAddress?: CAAdditionalEmailAddress;
  isOdAllocation: boolean = false;
  contractValidityType: string;
}
