export interface Theme {
  name: string;
  logo: string;
}

export const MAERSK: Theme = {
  name: 'maersk',
  logo: '/assets/img/maeu.svg'
};

export const SEALAND: Theme = {
  name: 'sealand',
  logo: '/assets/img/sealand_brand.svg'
};

export const HAS: Theme = {
  name: 'hamburgsud',
  logo: '/assets/img/hamburgsud_brand.svg'
};
