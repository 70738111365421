export class UserInfo {
  error: string;
  username: string;
  authenticated: boolean;
  token: string;
  profile: Profile;
}

export class Profile {
  aio: string;
  amr: string;
  exp: string;
  family_name: string;
  given_name: string;
  iat: string;
  ipaddr: string;
  iss: string;
  name: string;
  nbf: string;
  nonce: string;
  oid: string;
  onprem_sid: string;
  sub: string;
  tid: string;
  unique_name: string;
  upn: string;
  uti: string;
  ver: string;
  roles: string[];
}
