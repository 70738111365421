export const CAT_FORMATS = {
  parse: {
    dateInput: 'DD MMM YYYY',
    dateTimeInput: 'DD MMM YYYY HH:MM:SS'
  },
  angular_pipe: {
    DDMMYYYY: 'dd MMM yyyy',
  },
  display: {
    dateInput: 'DD MMM YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

export const CAT_DATE_FORMAT = {
  DDMMMYYYY: 'DD MMM YYYY',
  DDMMMYYYYHHmmss: 'DD MMM YYYY HH:mm:ss',
  YYYYMMDD: 'YYYY-MM-DD',
  DDMM: 'DD/MM'
};
