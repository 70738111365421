import {
  ActionReducerMap,
  MetaReducer
} from '@ngrx/store';
import { environment } from '../../../environments/environment';
import { BrandingState } from './branding-state';
import { brandingReducer } from '../reducers/branding-reducers';
import { referenceDataReducer } from '../reducers/reference-data-reducers';
import { ReferenceDataState } from './reference-data-state';


export interface AppState {
  branding: BrandingState,
  referenceData: ReferenceDataState;
}

export const reducers: ActionReducerMap<AppState> = {
  branding: brandingReducer,
  referenceData: referenceDataReducer
};

export const selectBranding = (state: AppState) => state.branding.branding;
export const selectReferenceData = (state: AppState) => state.referenceData.carFilters;
export const features = (state: AppState) => state.branding.features;


export const metaReducers: MetaReducer<AppState>[] = !environment.production ? [] : [];