import { CarFilters } from '../../common-models/filter.service.model';

export interface ReferenceDataState {
    carFilters: CarFilters | null;
    error: string | null;
}

export const initialReferenceDataState: ReferenceDataState = {
    carFilters: null,
    error: null
};