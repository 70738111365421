/**
 * CarFilters - Modal class to map resposne received from
 * filter service
 */
export class CarFilters {
  brands: Brand[];
  cargoTypes: string[];
  customerTypes: string[];
  reasons: CarActionReason[];
  statuses: Statuses[];
  clusters: string[];
  defaultMaxWeeklyVolume: number;
  allocationVariantType: string[];
  deliveryPromise: string[];
  verticals: Vertical[];
  configurations: CatConfigurationParameter[];
}

export class Brand {
  name: string;
  code: string;
  routeDirections: Route[];
  valuePropositions: string[];
}

export class Route {
  routeName: string;
  routeCode: string;
  routeDirection: string;
  brandCode: string;
  brandName: string;
  serviceDirections: Service[];
}

export class Service {
  serviceName: string;
  serviceCode: string;
  serviceDirection: string;
  routeName: string;
  routeCode: string;
  routeDirection: string;
  brandCode: string;
}

export class CarActionReason {
  action: string;
  actionReason: Status[];
}

export class Status {
  action: string;
  reasonCode: string;
  reasonDescription: string;
}

export class Statuses {
  statusCd: string;
  statusId: number;
  statusDesc: string;
}

export class UserData {
  firstName: string;
  lastName: string;
  emailAddress: string;
  uniqueId: string;
  cluster: string;
  jobFunction: string;
}

export interface Vertical{
  displayOrder: number,
  verticalCode: string,
  verticalDisplayName: string,
  verticalDescription: string
}

export class CatConfigurationParameter{
  paramName: string;
  paramValue: string;
}
