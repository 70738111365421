export const environment = {
  envName: 'bdd',
  production: true,
  skipAuthentication: false,
  mockPermission: [],
  allowedActionForState: {
    PENDING: {
      featureToggle: { counter: true, cancel: true, reject: true, confirm: true, edit: true }
    },
    CONFIRMED: {
      featureToggle: { cancel: true, update: true, renegotiation: true, editConfirmed: true, expire: true }
    },
    REJECTED: { featureToggle: true },
    CANCELLED: { featureToggle: true },
    RENEGOTIATION: {
      featureToggle: { editRenegotiated: true, rejectRenegotiation: true, acceptRenegotiation: true, cancelRenegotiation: true }
    },
    COUNTER_OFFERED: {
      featureToggle: { acceptCounterOffer: true, rejectCounterOffer: true }
    }
  },
  // OD secondary button feature toggling
  allowedSecondaryBtnOnPrimaryBtnClick: {
    RENEGOTIATION: {
      editRenegotiated: {
        featureToggle: { undo: true, submitAndAcceptRenegotiation: true, submitEditedRenegotiation: true }
      }
    }
  },
  // feature toggle cancel confirmed
  allowedActionForExistingAccelerateState: {
    CONFIRMED: {
      featureToggle: { cancel: true, editConfirmed: true }
    }
  },
  allowedActionForUnderApprovalAccelerateStates: {
    PENDING: {
      featureToggle: { counter: true, approve: true, reject: true }
    },
    COUNTER_OFFERED: {
      featureToggle: { acceptCounterOffer: true, rejectCounterOffer: true }
    }
  },
  enableAccelerateRequestFeature: true,


  enableOdgFeature: true,

  enableOdServiceContract: true,
  /** Sporadic filing feature flag for Mod */
  isSporadicFilingEnabled: true,

  stowageConsumerKey: 'YqDDYbAUkDIjYHiCVI8Pfy0UdCAEwFdw',

  enablePendingTrayFilter: {
    STEERED: true,
    OD: true,
    ODVAS: true
  },

  tabFeatureFlag: {
    allocation: true,
    accelerate: true,
    flexhub: true
  },

  api_cat_gw_url: 'https://api-bdd.staging.customerallocations.maersk.com/',
  api_location_root: 'https://api-sit.staging.customerallocations.maersk.com/odlocationconsumer/location/',
  api_hsud_cmd: `https://hsud-customer-contract-api-service-bdd-az.azurewebsites.net/cmd/v1/`,
  api_hsud_alfs: `https://hsud-customer-contract-api-service-bdd-az.azurewebsites.net/afls/v1/`,
  api_stowage_root:`https://api-cdt.maersk.com/customer-allocation/stowage/`,
  api_flexhub_root: `https://ca-vas-flexhub-service.dev.maersk-digital.net/customer-allocation/flexhub/`,
  api_accelerate_root: `https://fpp-od-vas-service.dev.maersk-digital.net/odvas/accelerate/`

};
