export class CACustomer {
  tradingName: string;
  customerCode: string;
  scvCode: string;
  masterSegment: string;
  valueProposition: string;
  serv: string;
  customerType: string;
  verticalCode: string;
}
