import {KeyValueModel} from '../../common-models/key-value-model';

export class Tile {
  id: string;
  imagePath: string;
  headerText: string;
  text: string;
  link: string;
  linkText: string;
  queryParams: KeyValueModel[] = [];
  permission: string;
}
