import { BrandingActionTypes, BrandingAction } from '../actions/branding.actions';
import { initialBrandingState, BrandingState } from '../state/branding-state';

export function brandingReducer(state: BrandingState = initialBrandingState, action: BrandingAction): BrandingState {
  switch (action.type) {
      case BrandingActionTypes.LoadBranding:
      return {
        ...state,
        branding: action.payload.branding,
        features: action.payload.features,
        error: null
      };

      case BrandingActionTypes.BrandingError:
      return {
        ...state,
        branding: null,
        features: null,
        error: action.payload.error
      };

    default:
      return state;
  }
}
