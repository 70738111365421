import {Injectable} from '@angular/core';

@Injectable()
export class NavigationService {

  readonly ENTER: string = 'Enter';
  readonly TAB: string = 'Tab';

  /**
   * isEnterKey() - Method to check if Enter Key is pressed
   * @param $event
   */
  isEnterKey($event) {
    return ($event.key === this.ENTER);
  }

  /**
   * Method to generate Enter event programtically
   */
  getEnterKeyEvent(): KeyboardEvent {
    return new KeyboardEvent('keyup', {
      key: this.ENTER
    });
  }

  /**
   * Method to generate Enter event programtically
   */
  getTabEvent(): KeyboardEvent {
    return new KeyboardEvent('keyup', {
      key: this.TAB
    });
  }
}
