import {Injectable} from '@angular/core';
import {CustomerAllocationAgreementValidationModel} from '../customer-allocation-agreement/customer-allocation-agreement-validation-model';
import {regExpressionsPatternConst} from '../shared-module/cat-constant';


@Injectable()
export class CatFormValidationService {


  /**
   * This function initializes model for validating "customer code"
   */
  initializeCustomerCodeValidation() {
    let customerCodeValidationModel: CustomerAllocationAgreementValidationModel = new CustomerAllocationAgreementValidationModel();
    customerCodeValidationModel.isUndefinedAllowed = false;
    customerCodeValidationModel.mandatoryMsg = 'cat.createRequest.messages.customerCodeRegexErrorMsg';
    customerCodeValidationModel.maxLength = 11;
    customerCodeValidationModel.maxLengthMsg = 'cat.createRequest.messages.customerCodeRegexErrorMsg';
    customerCodeValidationModel.minLength = 1;
    customerCodeValidationModel.minLengthMsg = 'cat.createRequest.messages.customerCodeRegexErrorMsg';
    customerCodeValidationModel.regex = regExpressionsPatternConst.customerCodeWhiteListRegex;
    customerCodeValidationModel.regexMsg = 'cat.createRequest.messages.customerCodeRegexErrorMsg';
    return customerCodeValidationModel;
  }

  /**
   * This function initializes model for validating "customer code"
   */
  initializeScvCodeValidation() {
    let customerCodeValidationModel: CustomerAllocationAgreementValidationModel = new CustomerAllocationAgreementValidationModel();
    customerCodeValidationModel.isUndefinedAllowed = false;
    customerCodeValidationModel.mandatoryMsg = 'cat.createRequest.messages.scvCodeRegexErrorMsg';
    customerCodeValidationModel.maxLength = 11;
    customerCodeValidationModel.maxLengthMsg = 'cat.createRequest.messages.scvCodeRegexErrorMsg';
    customerCodeValidationModel.minLength = 1;
    customerCodeValidationModel.minLengthMsg = 'cat.createRequest.messages.scvCodeRegexErrorMsg';
    customerCodeValidationModel.regex = regExpressionsPatternConst.customerCodeWhiteListRegex;
    customerCodeValidationModel.regexMsg = 'cat.createRequest.messages.scvCodeRegexErrorMsg';
    return customerCodeValidationModel;
  }

  /**
   * This function initializes model for validating "customer name"
   */
  initializeCustomerNameValidation() {
    let customerNameValidationModel: CustomerAllocationAgreementValidationModel = new CustomerAllocationAgreementValidationModel();
    customerNameValidationModel.isUndefinedAllowed = false;
    customerNameValidationModel.minLength = 3;
    customerNameValidationModel.minLengthMsg = 'cat.createRequest.messages.tradingNameMinimumLengthErrorMsg';
    customerNameValidationModel.mandatoryMsg = 'cat.createRequest.messages.customerNameRequiredErrorMsg';
    customerNameValidationModel.regexMsg = 'cat.createRequest.messages.customerNameRequiredErrorMsg';
    return customerNameValidationModel;
  }

  /**
   * This function initializes model for validating "customer country"
   */
  initializeCustomerCountryValidation() {
    let customerCountryValidationModel: CustomerAllocationAgreementValidationModel = new CustomerAllocationAgreementValidationModel();
    customerCountryValidationModel.isUndefinedAllowed = false;
    customerCountryValidationModel.mandatoryMsg = 'cat.createRequest.messages.customerCountryRequiredErrorMsg';
    customerCountryValidationModel.regexMsg = 'cat.createRequest.messages.customerCountryRequiredErrorMsg';
    return customerCountryValidationModel;
  }

  /**
   * This function initializes model for validating "Service Contract Numbers"
   */
  initializeServiceContractValidation(regEx: RegExp = /^[0-9]*$/) {
    let serviceContractValidationModel: CustomerAllocationAgreementValidationModel = new CustomerAllocationAgreementValidationModel();
    serviceContractValidationModel.regex = regEx;
    serviceContractValidationModel.isUndefinedAllowed = false;
    serviceContractValidationModel.mandatoryMsg = 'cat.createRequest.messages.serviceContractRequiredErrorMsg';
    serviceContractValidationModel.regexMsg = 'cat.createRequest.messages.serviceContractPatternErrorMsg';
    serviceContractValidationModel.minLength = 7;
    serviceContractValidationModel.minLengthMsg = 'cat.createRequest.messages.serviceContractPatternErrorMsg';
    serviceContractValidationModel.maxLength = 12;
    serviceContractValidationModel.maxLengthMsg = 'cat.createRequest.messages.serviceContractPatternErrorMsg';
    return serviceContractValidationModel;
  }

  /**
   * This function initializes model for validating "for multiple Service Contract Numbers"
   */
  initializeForMultipleServiceContractValidation(index: number) {
    let serviceContractValidationModel: CustomerAllocationAgreementValidationModel = new CustomerAllocationAgreementValidationModel();
    serviceContractValidationModel.erroMsgKey = 'serviceContractNo-' + index;
    serviceContractValidationModel.mandatoryMsg = 'cat.createRequest.messages.serviceContractRequiredErrorMsg';
    serviceContractValidationModel.isUndefinedAllowed = false;
    serviceContractValidationModel.regex = /^[0-9]*$/;
    serviceContractValidationModel.minLength = 7;
    serviceContractValidationModel.minLengthMsg = 'cat.createRequest.messages.serviceContractPatternErrorMsg';
    serviceContractValidationModel.maxLength = 12;
    serviceContractValidationModel.maxLengthMsg = 'cat.createRequest.messages.serviceContractPatternErrorMsg';
    serviceContractValidationModel.regexMsg = 'cat.createRequest.messages.serviceContractPatternErrorMsg';
    return serviceContractValidationModel;
  }

  /**
   * This function initializes model for validating "Comments"
   */
  initializeCommentsValidation() {
    let commentsValidationModel: CustomerAllocationAgreementValidationModel = new CustomerAllocationAgreementValidationModel();
    commentsValidationModel.erroMsgKey = 'commentsLabel';
    commentsValidationModel.mandatoryMsg = 'cat.createRequest.messages.commentsErrorMessage';
    commentsValidationModel.isUndefinedAllowed = false;
    return commentsValidationModel;
  }

  /**
   * This function initializes model for validating "Email"
   */
  initializeEmailValidation(isHamburgsudMode: boolean) {
    let customerNameValidationModel: CustomerAllocationAgreementValidationModel = new CustomerAllocationAgreementValidationModel();
    customerNameValidationModel.isUndefinedAllowed = false;
    customerNameValidationModel.mandatoryMsg = 'cat._request_details.messages.emailAddressMandatoryMsg';
    customerNameValidationModel.erroMsgKey = 'inputAddEmailAddress';
    if (!isHamburgsudMode){
      customerNameValidationModel.regex = regExpressionsPatternConst.emailAddressRegex;
    } else {
      customerNameValidationModel.regex = regExpressionsPatternConst.emailAddressRegexHAS;
    }
    customerNameValidationModel.regexMsg = 'cat._request_details.messages.emailAddressInvalidMsg';
    return customerNameValidationModel;
  }

  /**
   * This function initializes model for validating "Route"
   */
  initializeRouteValidation() {
    let routeValidationModel: CustomerAllocationAgreementValidationModel = new CustomerAllocationAgreementValidationModel();
    routeValidationModel.erroMsgKey = 'routeComponent';
    routeValidationModel.mandatoryMsg = 'cat.createRequest.messages.routeDirectionRequiredErrorMsg';
    routeValidationModel.isUndefinedAllowed = false;
    routeValidationModel.regexMsg = 'cat.createRequest.messages.routeDirectionRequiredErrorMsg';
    return routeValidationModel;
  }


}
