import { Action } from '@ngrx/store';
import { CarFilters } from '../../common-models/filter.service.model';

export enum ReferenceDataActionTypes{
  LoadReferenceData = '[ReferenceData] Load ReferenceDatas',
  ReferenceDataLoadComplete = '[ReferenceData] Load Complete ReferenceDatas',
  ReferenceDataError = '[ReferenceData] ReferenceDatas error'
}

export class ReferenceDataAction implements Action {
  type: string;
  payload: {
    referenceData: CarFilters,
    error: string
  };
}

export class LoadReferenceData implements Action {
  readonly type = ReferenceDataActionTypes.LoadReferenceData;

}

export class ReferenceDataLoadComplete implements Action {
  readonly type = ReferenceDataActionTypes.ReferenceDataLoadComplete;

  constructor(readonly payload: { referenceData: CarFilters }) {
  }
}

export class ReferenceDataError implements Action {
  readonly type = ReferenceDataActionTypes.ReferenceDataError;

  constructor(readonly payload: { error: string }) {

  }
}

export type ReferenceAction = LoadReferenceData | ReferenceDataLoadComplete | ReferenceDataError;
