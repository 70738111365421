import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';

import { environment } from '../../environments/environment';
import { Weeks } from './filter.service.week.reference';
import { Brand, CarActionReason, Route, Service, Statuses, UserData, CarFilters } from '../common-models/filter.service.model';
import { UtilityService } from './utility.service';
import { ExceptionService } from '../exceptions/exception.service';
import { KeyValueModel } from '../common-models/key-value-model';
import { CARoute } from '../common-models/customer-allocation-route-model';
import { environmentEndpoints } from '../../environments/environment.endpoints';
import { OdStatus } from '../common-models/od-allocation-model';
import { CASteeredService } from '../common-models/customer-allocation-steered-service-model';
import { BrandRule } from '../common-models/product-rule.model';
import { catchError, map } from 'rxjs/operators';

const { api_cat_gw_url, api_location_root } = environment;
const { api_ca_reference_root, tool_reference, week_dist_reference, quarterly_weeks, userClusterInfo, weekNumberInfo, allProductRule, quarterWeeksMapping } = environmentEndpoints;

@Injectable()
export class FilterService {

  /**
   * @constructor
   * @param http
   */
  constructor(private http: HttpClient, private utilityService: UtilityService,
    private exceptionService: ExceptionService) {
  }

  /**
   * loadCarFilter() - Method to get data from Filter Service
   */
  loadCarFilter(): Observable<CarFilters> {
    return this.http.get<CarFilters>(`${api_cat_gw_url}${api_ca_reference_root}${tool_reference}`)
    .pipe(
      map((response) => response),
      catchError(this.errorHandler));
    ;
  }

  /**
   * Function will fetch all the rules for all products
   */
  loadProductRules(): Observable<BrandRule[]> {
    return this.http.get(`${api_cat_gw_url}${api_ca_reference_root}${allProductRule}`)
      .pipe(
        map((response) => response),
        catchError(this.errorHandler));
  }

  /**
   * loadWeekReference() - Method to load week reference mapping
   * @param startDate
   * @param endDate
   */
  loadWeekReference(startDate: string, endDate: string): Observable<Weeks> {
    const url = `${api_cat_gw_url}${api_ca_reference_root}${week_dist_reference}startDate=${startDate}&endDate=${endDate}`;
    return this.http.get(url).pipe(
      map((response) => response),
      catchError(this.errorHandler));
  }

  /**
   * loadWeekReference() - Method to load week reference mapping
   * @param startDate
   * @param endDate
   */
  loadQuarterlyWeeks(startDate: string, endDate: string): Observable<Weeks> {
    const url = `${api_cat_gw_url}${api_ca_reference_root}${quarterly_weeks}startDate=${startDate}&endDate=${endDate}`;
    return this.http.get(url).pipe(
      map((response) => response),
      catchError(this.errorHandler));
  }  

  fetchCountryMasterList(): Observable<any> {
    return this.http.get('../assets/data/scv-customercode-transformation.json')
    .pipe(catchError(this.errorHandler));
  }

  /**
   * getUserClusterInfo() - Method to get Cluster Info for the Logged In User
   */
  getUserClusterInfo(): Observable<UserData> {
    return this.http.get(`${api_cat_gw_url}${api_ca_reference_root}${userClusterInfo}`).pipe(
      map((response) => response),
      catchError(this.errorHandler));
  }

  /**
   * errorHandler() - Method to handle errors in the services
   * @param error
   */
  errorHandler = (error: Response | any) => {
    this.exceptionService.processErrors(error);
    return of(error);
  }

  /**
   * getBrandByKey() - Method to get brand by Code
   * @param carFilters
   * @param selectedBrand
   */
  getBrandNameByCode(carFilters: CarFilters, brandCode: string): string {
    let brandName = '';
    carFilters?.brands?.forEach(brand => {
      if (brand.code === brandCode) {
        brandName = brand.name;
      }
    });
    return brandName;
  }

  /**
   * getReason() - Method to get reason
   * @param carFilters
   * @param action
   */
  getReasons(carFilters: CarFilters, action: string): CarActionReason {
    let actionReasons: CarActionReason;
    carFilters?.reasons?.forEach(filterActionReason => {
      if (filterActionReason.action === action) {
        actionReasons = filterActionReason;
      }
    });
    return actionReasons;
  }

  /**
   * getRouteDirections() -Method to extract route direction based on
   * the selected brand
   * @param carFilters
   * @param selectedBrand
   */
  getRouteDirections(carFilters: CarFilters, selectedBrand: string): Route[] {
    let routeDirections: Route[] = [];
    if (selectedBrand !== 'ALL') {
      carFilters?.brands?.forEach(brand => {
        if (brand.code === selectedBrand) {
          routeDirections = brand.routeDirections;
        }
      });
    } else {
      carFilters?.brands?.forEach(brands => {
        brands?.routeDirections?.forEach(routeDirection => {
          routeDirections.push(routeDirection);
        });
      });
    }
    return routeDirections;
  }

  /**
   * extractRouteDirections() - Return the list of options based on the route
   *  direction passed from the filter service
   * @param routeList
   */
  extractRouteDirections(routeList: Route[]): KeyValueModel[] {
    const routes: KeyValueModel[] = [];
    routeList?.forEach(route => {
      routes.push(this.utilityService.getRouteDirectionItem(route));
    });
    return routes;
  }

  /**
   * getServiceDirection() - Returns list of option for service dropdown based
   * on the filterdata received from filter service and route directions selected in the drop down
   * @param carFilters
   * @param routeDirectionList
   */
  getServiceDirection(carFilters: CarFilters, routeDirectionList: KeyValueModel[], selectedBrand: string): KeyValueModel[] {
    const services: KeyValueModel[] = [];
    this.fetchServices(carFilters, routeDirectionList, selectedBrand)?.forEach(serviceDirection => {
      services.push(this.utilityService.getServiceDirectionItem(serviceDirection));
    });
    return this.utilityService.removeDuplicate(services);
  }

  /**
   * fetchServices() - Fetches all the services from the reference service
   * @param carFilters
   */
  fetchServices(carFilters: CarFilters, selectedRouteDirectionList: KeyValueModel[], selectedBrand): Service[] {
    const serviceDirections: Service[] = [];
    let routeDirections: Route[] = [];

    // If Brand is All and Route Direction is selected
    if (selectedBrand === 'ALL' && selectedRouteDirectionList.length > 0) {
      carFilters?.brands?.forEach(brand => {
        this.getRouteDirectionFromBrandForSelectedRoutes(brand, selectedRouteDirectionList)?.forEach(routeDirection => {
          routeDirections.push(routeDirection);
        });
      });
    } else if (selectedBrand !== 'ALL' && selectedRouteDirectionList.length === 0) {
      carFilters?.brands?.forEach(brand => {
        if (brand.code === selectedBrand) {
          brand?.routeDirections?.forEach(routeDirection => {
            routeDirections.push(routeDirection);
          });
        }
      });
    } else if (selectedBrand !== 'ALL' && selectedRouteDirectionList.length > 0) {
      carFilters?.brands?.forEach(brand => {
        if (brand.code === selectedBrand) {
          routeDirections = this.getRouteDirectionFromBrandForSelectedRoutes(brand, selectedRouteDirectionList);
        }
      });
    } else {
      carFilters?.brands?.forEach(brand => {
        brand?.routeDirections?.forEach(routeDirection => {
          routeDirections.push(routeDirection);
        });
      });
    }

    routeDirections?.forEach(routeDirection => {
      routeDirection?.serviceDirections?.forEach(serviceDirection => {
        serviceDirections.push(serviceDirection);
      });
    });
    return serviceDirections;
  }

  /**
   * getRouteDirectionFromBrandForSelectedRoutes() - Method to extract route
   * direction from brand for selected route directions
   * @param brand
   * @param routeDirectionList
   */
  getRouteDirectionFromBrandForSelectedRoutes(brand: Brand, routeDirectionList: KeyValueModel[]): Route[] {
    const routeDirections: Route[] = [];
    brand?.routeDirections?.forEach(routeDirection => {
      if (this.utilityService.objectIndexOf(routeDirectionList, this.utilityService.getRouteDirectionItem(routeDirection)) > -1) {
        routeDirections.push(routeDirection);
      }
    });
    return routeDirections;
  }

  /**
   * getCustomerTypeFromFilterServiceData() - Extracts the customer type list from the data received from
   * filter service
   * @param carFilters
   */
  getCustomerTypeFromFilterServiceData(carFilters: CarFilters) {
    const customerTypeList = [];
    let item: KeyValueModel;
    carFilters?.customerTypes?.forEach((customer) => {
      item = new KeyValueModel(customer, customer);
      customerTypeList.push(item);
    });
    return customerTypeList;
  }

  /**
   * filterValuePropositionByBrand() - Method to  filter the valuePropositionList based on the selected brand
   * @param carFilters
   * @param selectedBrand
   */
  filterValuePropositionByBrand(carFilters: CarFilters, selectedBrand: string): KeyValueModel[] {
    const valuePropositionList: KeyValueModel[] = [];
    const uniqueValuePropositionList: Set<string> = new Set<string>();
    let item: KeyValueModel;
    if (selectedBrand !== 'ALL') {
      carFilters?.brands?.forEach(brand => {
        if (brand.code === selectedBrand) {
          brand?.valuePropositions?.forEach((vp, index) => {
            item = new KeyValueModel(vp, vp);
            valuePropositionList.push(item);
          });
        }
      });
    } else {
      carFilters?.brands?.forEach(brand => {
        brand?.valuePropositions?.forEach((vp, index) => {
          uniqueValuePropositionList.add(vp);
        });
      });
      uniqueValuePropositionList?.forEach(vp => {
        item = new KeyValueModel(vp, vp);
        valuePropositionList.push(item);
      });
    }
    return valuePropositionList;
  }

  /**
   * getSelectedServiceDirection() - Get Selected Service direction object from list
   * @param serviceDirectionList
   * @param filteredServiceDirectionList
   */
  getSelectedServiceDirection(serviceDirectionList: Service[], filteredServiceDirectionList: KeyValueModel[]) {
    const tempServiceDirectionList = [];
    serviceDirectionList?.forEach(service => {
      tempServiceDirectionList[service.serviceCode + service.serviceDirection] = service;
    });
    const filteredList: Service[] = [];
    filteredServiceDirectionList?.forEach(service => {
      filteredList.push(tempServiceDirectionList[service.key.split(';')[0]]);
    });
    return filteredList;
  }

  /**
   *
   * @param carFilters
   * @param brand
   */
  getSelectedBrand(carFilters: CarFilters, brand: string): Brand {
    const brandList = [];
    carFilters?.brands?.forEach(brand => {
      brandList[brand.code] = brand;
    });

    return brandList[brand];
  }

  /**
   * getRouteModelFromRouteDirection() - Method to get Route Model for Request
   * @param routeDirection
   */
  getRouteModelFromRouteDirection(routeDirection: Route): CARoute {
    const routeModel: CARoute = new CARoute();
    routeModel.code = routeDirection.routeCode;
    routeModel.direction = routeDirection.routeDirection;
    routeModel.name = routeDirection.routeName;
    return routeModel;
  }

  /**
   * getStatusDesByCode() - Method to get Status Description by Status Code
   * @param status
   */
  getStatusByCode(carFilters: CarFilters, requestStatus: string) {
    let status: Statuses = new Statuses();
    carFilters?.statuses?.forEach(carStatus => {
      if (carStatus.statusCd === requestStatus) {
        status = carStatus;
      }
    });
    return status;
  }

  /**
   * getRequestStatusList() - Method to get the list of the status from the response of Referencedata API
   * @param carFilters
   */
  getRequestStatusList(carFilters: CarFilters): Statuses[] {
    return carFilters.statuses;
  }

  /**
   *getClustersList() - Method to return Cluster List from Filter Service
   * @param carFilters
   */
  getClustersList(carFilters: CarFilters): string[] {
    return carFilters.clusters;
  }

  /**
   * getCargoTypeWithOutAll() - Method to get Cargo Type after removing All
   * @param carFilters
   */
  getCargoTypeWithOutAll(carFilters: CarFilters) {
    const cargoTypes: string[] = this.utilityService.getDeepCloneOfObject(carFilters.cargoTypes);
    cargoTypes.splice(cargoTypes.indexOf('All'), 1);
    return cargoTypes.map(cargoType => cargoType.toUpperCase());
  }


  /**
   * getWeekAndQuarterObjForGivenDate() - Method to call getCurrentWeekAndQuarter API
   * @param date
   */
  getWeekAndQuarterObjForGivenDate(date: string): Observable<Weeks> {
    return this.http.get(api_cat_gw_url+api_ca_reference_root + weekNumberInfo + '/' + date)
    .pipe(
      map((response) => response),
      catchError(this.errorHandler));
  }

  /**
   * getStatusDesByCode() - Method to get Status Description by Status Code
   * @param carFilters
   * @param requestStatus
   */
  getOdStatusByCode(carFilters: CarFilters, requestStatus: string): OdStatus {
    const status: OdStatus = new OdStatus();
    const carStatus = carFilters?.statuses?.find(status => status.statusCd === requestStatus);
    status.code = carStatus.statusCd;
    status.description = carStatus.statusDesc;
    status.id = carStatus.statusId;
    return status;
  }

  /**
   * getServiceDirectionInRoute() - Method to extract Service Directions
   *  based on route direction and Brand
   * @param carFilters
   * @param brandCode
   * @param route
   */
  getServiceDirectionInRoute(carFilters: CarFilters, brandCode: string, caRoute: CARoute): Service[] {
    const brand: Brand = carFilters?.brands?.find(brand => brand.code === brandCode);
    if (brand) {
      const routeDirection: Route = brand?.routeDirections?.find(routeDirection => routeDirection.routeCode === caRoute.code
        && routeDirection.routeDirection === caRoute.direction);
      if (routeDirection && routeDirection.serviceDirections) {
        return routeDirection.serviceDirections;
      }
    }
    return null;
  }

  /**
   * getSelectedService() - Method to get Reference Service Model from request service model
   * @param serviceDirectionList
   * @param filteredServiceDirectionList
   */
  getSelectedService(serviceDirectionList: Service[], serviceInRequest: CASteeredService): Service {
    if (serviceDirectionList) {
      return serviceDirectionList.filter(serviceModel => serviceModel.serviceCode === serviceInRequest.steeredServiceCd
        && serviceModel.serviceDirection === serviceInRequest.direction
        && serviceModel.serviceName === serviceInRequest.name)[0];
    }
    return null;
  }

  getCustomerTypes(customerTypesArray: string[]): KeyValueModel[] {
    const customerTypes: KeyValueModel[] = [];
    customerTypesArray?.forEach(customer => {
      customerTypes.push(this.utilityService.getCustomerTypeItem(customer));
    });
    return customerTypes;
  }

  /**
   * This method returns quarter week master data for input 'startDate' and 'endDate'.
   * This API response is based on the special logic for returning master data.
   *
   * The response includes all records from the quarters which are lying within the boundries
   * of week numbers corresponding to the 'startDate' and 'endDate'.
   */
  loadWeekQuarterReference(startDate: string, endDate: string): Observable<Weeks> {
    const url = `${api_cat_gw_url}${api_ca_reference_root}${quarterWeeksMapping}startDate=${startDate}&endDate=${endDate}`;
    return this.http.get(url).pipe(
      map((response) => response),
      catchError(this.errorHandler));
  }

  /**
   * This function returns delivery promise master list from input 'carFilters'
   * @param carFilters
   */
  getDeliveryPromiseList(carFilters: CarFilters): string[] {
    return carFilters?.deliveryPromise;
  }
}
