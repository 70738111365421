import {Injectable} from '@angular/core';
//
import {environment} from '../../environments/environment';
import {NgxPermissionsService, NgxRolesService} from 'ngx-permissions';
import {PermissionService} from './permission.service';

import {UserRole} from './user-role.model';
import {UserRoleService} from './user-role.service';

@Injectable()
/**
 * AuthenticatorService - Class to handle all the authentication and access control
 */
export class AuthenticatorService {

  skipAuthentication: boolean = false;
  // URL to load permission
  userRoles: UserRole[];

  constructor(
    
    private ngxPermissionService: NgxPermissionsService,
    private ngxRolesService: NgxRolesService,
    private permissionService: PermissionService,
    private userRoleService: UserRoleService) {
  }

  /**
   * preparePermissions() - Initializes the permissions
   */
  preparePermissions() {
    if (!this.skipAuthentication) {
      this.permissionService.loadPermissions().subscribe(res => {
        this.userRoles = res;
        this.cookPermissions(this.userRoles);
      });
    } else {
      this.prepareMockPermissions();
    }
  }

  /**
   * cookPermissions() - Method to prepare all the permission
   *  and add it to NGXPermission
   * @param userRoles
   */
  cookPermissions(userRoles: UserRole[]) {
    let permissions: Set<string> = new Set<string>();
    if (userRoles && userRoles.length > 0) {
      userRoles.forEach(userRole => {
        userRole.permissions.forEach(permission => {
          permissions.add(permission);
        });
        this.ngxRolesService.addRole(userRole.roleName, userRole.permissions);
      });
      this.ngxPermissionService.loadPermissions(Array.from(permissions));
    } else {
      this.prepareGuestPermissions();
    }
    this.userRoleService.changeMessage(this.userRoles);
  }

  /**
   * prepareGuestPermissions() - Method to login as Guest Account
   */
  prepareGuestPermissions() {
    let guestPermission: string[] = ['ViewAllocation'];
    this.userRoles = [{roleName: 'Guest', permissions: guestPermission}];
    this.ngxRolesService.addRole('Guest', guestPermission);
    this.ngxPermissionService.loadPermissions(guestPermission);
  }

  /**
   * prepareDummyPermissions() - Method to inject dummy permission in case of
   */
  prepareMockPermissions() {
    this.ngxRolesService.addRole('Guest', environment.mockPermission);
    this.ngxPermissionService.loadPermissions(environment.mockPermission);
  }
}
