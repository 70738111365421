import { Theme } from '../../themes/theme';

export interface BrandingState {
  branding: Theme| null;
  features: string[] | null;
  error: string | null;
}

export const initialBrandingState: BrandingState = {
  branding: null,
  features: [],
  error: null
};
