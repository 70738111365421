import {Injectable} from '@angular/core';
import {Observable, of ,BehaviorSubject } from 'rxjs';
import {environment} from '../../environments/environment';
import {ReleaseVersions} from './release-updates.model';
import {HttpClient} from '@angular/common/http';
import {ExceptionService} from '../exceptions/exception.service';
import {DowntimeNotifications} from './downtime.model';
import {environmentEndpoints} from '../../environments/environment.endpoints';
import { catchError, map } from 'rxjs/operators';

const {api_cat_gw_url} = environment;
const {downtimeURL, notesConfig, releaseNotesURL,api_ca_reference_root} = environmentEndpoints;

@Injectable()
export class ReleaseUpdatesService {

  inputPromptActionSource: BehaviorSubject<string> = new BehaviorSubject<string>('');
  inputPromptActionListner = this.inputPromptActionSource.asObservable();

  constructor(private http: HttpClient, private exceptionService: ExceptionService) {
  }


  /**
   * getReleaseNotes() - Method to Fetch release notes
   */
  getReleaseNotes(): Observable<ReleaseVersions> {
    let url: string = `${api_cat_gw_url}${api_ca_reference_root}${notesConfig}${releaseNotesURL}/2`;
    return this.http.get(url)
    .pipe(
      map(response => response),
      catchError(error => this.errorHandler(error)));
  }


  /**
   * errorHandler() - Method to handle errors in the services
   * @param error
   */
  errorHandler(error: Response | any) {
    this.exceptionService.processErrors(error);
    return of(error);
  }

  /**
   * getDowntime() - Method to Fetch downtime
   */
  getDowntime(): Observable<DowntimeNotifications> {
    let url: string = `${api_cat_gw_url}${api_ca_reference_root}${notesConfig}${downtimeURL}`;
    return this.http.get(url).pipe(
      map(response => response),
      catchError(error => this.errorHandler(error)));
  }

  /**
   * saveReleaseNotes() - Method to save new release note
   */
  saveReleaseNotes(update: any, action: string): Observable<ReleaseVersions> {
    let url: string = `${api_cat_gw_url}${api_ca_reference_root}${notesConfig}${action}`;
    return this.http.post(url, update).pipe(
      map(response => response),
      catchError(error => this.errorHandler(error)));
  }

  openInputPromptDialogue() {
    this.inputPromptActionSource.next('open');
  }


}
