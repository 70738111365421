import {regExpressionsPatternConst} from '../shared-module/cat-constant';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {environmentEndpoints} from '../../environments/environment.endpoints';
import {HttpClient} from '@angular/common/http';

export function validateAllocationInput(inputValue: number) {
  let input: string = inputValue.toString();
  let pattern: RegExp = regExpressionsPatternConst.allocationInputRegex;
  return (pattern.test(input) && input.slice(-1) !== '.') || input === '';

}

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, environmentEndpoints.langRootURL, '.json');
}
