import { Injectable} from '@angular/core';
import { DatePipe, DecimalPipe } from '@angular/common';
import {DomSanitizer} from "@angular/platform-browser";
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';
import {Tile} from '../user-controls/tile/tile';
import {KeyValueModel} from '../common-models/key-value-model';
import * as dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';
import {CAT_DATE_FORMAT} from '../customer-allocation-agreement-date-formats';
import {CASteeredService} from '../common-models/customer-allocation-steered-service-model';
import {CARoute} from '../common-models/customer-allocation-route-model';
import {odAllocationTypeOptionsList, validityType} from '../shared-module/cat-constant';
import {Week, Weeks} from './filter.service.week.reference';
import {domainUrlRegExp} from '../../environments/domain';

import {applicationConstants} from '../common-services/constants.service';
import {Brand, CarFilters, Route, Service} from '../common-models/filter.service.model';
import {ServiceWeeklySplit} from '../user-controls/weekly-split/weekly-split.model';
import {FeatureConfigsService} from '../feature/feature-configs.service';
import { DomainService } from '../authorization/domain.service';

const imagePath = '../assets/img/';
export const splitEditType = {'TOTAL': 'TOTAL', 'REMAINING': 'REMAINING'};
export const splitType = {'EVEN': 'EVEN', 'UNEVEN': 'UNEVEN'};
export const overSizeTextLimit: number = 20;

@Injectable()
export class UtilityService {

  constructor(private translate: TranslateService, private router: Router,
              private featureConfigsService: FeatureConfigsService,
              private sanitizer: DomSanitizer,
              private domainService: DomainService) {
  }

  /**
   * loadTranslatedField() - Method to return the i18n converted field based on key passed
   * @param key
   */
  loadTranslatedField(key: string): string {
    let value = '';
    this.translate.get(key).subscribe((res: string) => {
      value = res;
    });
    return value;
  }

  /**
   * checkEligibility() - Method to check if the string is found in list or not
   * @param list
   * @param status
   */
  containsString(list: string[], status: string) {
    return list.indexOf(status) !== -1;
  }

  /**
   * transformDate() - Method to convert date to UI specific format
   * @param inputDate
   */
  transformDate(inputDate: string): string {
    let datePipe: DatePipe = new DatePipe('en-US');
    return datePipe.transform(inputDate, 'dd MMM yyyy');
  }


  /**
   * isNumber() - Method to check if value is a number
   * @param n
   */
  isNumber(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
  }

  /**
   * ObjectIndexOf() - Method to check if given object exists in the array
   * @param itemList
   * @param searchItem
   */
  objectIndexOf(itemList: KeyValueModel[], searchItem: KeyValueModel): number {
    for (let i = 0; i < itemList.length; i++) {
      if (itemList[i].key === searchItem.key && itemList[i].value === searchItem.value) {
        return i;
      }
    }
    return -1;
  }

  /**
   * objectIndexOfByValue() - Method to check if given object exists in the array
   * @param itemList
   * @param searchItem
   */
  objectIndexOfByValue(itemList: KeyValueModel[], searchItem: KeyValueModel): number {
    for (let i = 0; i < itemList.length; i++) {
      if (itemList[i].value === searchItem.value) {
        return i;
      }
    }
    return -1;
  }

  /**
   * getObjectByKey() - Method to return the Object from Array
   * based on the key passed
   * @param itemList
   * @param key
   */
  getObjectByKey(itemList: KeyValueModel[], key: string): KeyValueModel {
    for (let i = 0; i < itemList.length; i++) {
      if (itemList[i].key === key) {
        return itemList[i];
      }
    }
    return undefined;
  }

  /**
   * getIndexOfObjectByKey() - Method to return the Object from Array
   * based on the key passed
   * @param itemList
   * @param key
   */
  getIndexOfObjectByKey(itemList: KeyValueModel[], key: string): number {
    for (let i = 0; i < itemList.length; i++) {
      if (itemList[i].key === key) {
        return i;
      }
    }
    return -1;
  }

  /**
   * removeDuplicate() - Method to remove duplicate from the Object Array
   * @param itemList
   */
  removeDuplicate(itemList: KeyValueModel[]): KeyValueModel[] {
    let filterList: KeyValueModel[] = [];
    itemList?.forEach(item => {
      if (this.objectIndexOf(filterList, item) === -1) {
        filterList.push(item);
      }
    });
    return filterList;
  }

  /**
   * removeDuplicateForServiceList() - Method to remove duplicate from the service list based on the service code
   * @param serviceList
   */
  removeDuplicateForServiceList(serviceList): any {
    let unique = serviceList;
    unique?.forEach((item, index) => {
      if (index !== unique?.findIndex(i => i.serviceCode === item.serviceCode)) {
        unique.splice(index, 1);
      }
    });
    return unique;
  }

  /**
   * routeDirectionItem() - Method to format Route Direction Autocomplete
   * @param routeDirection
   */
  getRouteDirectionItem(routeDirection: Route): KeyValueModel {
    let key = routeDirection.routeCode + routeDirection.routeDirection + ';' + routeDirection.routeName;
    let value = routeDirection.routeCode + ' - ' + routeDirection.routeDirection + ' (' + routeDirection.routeName + ')';
    return new KeyValueModel(key, value);
  }

  /**
   * getCustomerTypeItem() - Method to format Customer Type item as intended to be sent to API to
   * get filtered curtailment requests
   * @param customer
   */
  getCustomerTypeItem(customer: string): KeyValueModel {
    let key = customer?.replace('-', '');
    return new KeyValueModel(key, customer);
  }

  /**
   * routeDirectionItem() - Method to format Route Direction Autocomplete
   * @param routeModel
   */
  getRouteDirectionItemFromRouteModel(routeModel: CARoute): KeyValueModel {
    let key = routeModel.code + routeModel.direction + ';' + routeModel.name;
    let value = routeModel.code + ' - ' + routeModel.direction + ' (' + routeModel.name + ')';
    return new KeyValueModel(key, value);
  }

  /**
   * getServiceDirectionItemFromSteeredServiceDirection() - Method to format Service Direction from Steered Service Model
   * @param steeredServiceDirection
   */
  getServiceDirectionItemFromSteeredServiceDirection(steeredServiceDirection: CASteeredService): KeyValueModel {
    let key = steeredServiceDirection.steeredServiceCd + (steeredServiceDirection.direction ? steeredServiceDirection.direction : '') + ';' + steeredServiceDirection.name;
    let value = steeredServiceDirection.steeredServiceCd + (steeredServiceDirection.direction ? ' - ' + steeredServiceDirection.direction : '') + ' (' + steeredServiceDirection.name + ')';
    return new KeyValueModel(key, value);
  }

  /**
   * serviceDirectionItem() - Method to format Service Direction Autocomplete
   * @param service
   */
  getServiceDirectionItem(service: Service): KeyValueModel {
    let key = service.serviceCode + (service.serviceDirection ? service.serviceDirection : '') + ';' + service.serviceName;
    let value = service.serviceCode + (service.serviceDirection ? ' - ' + service.serviceDirection : '') + ' (' + service.serviceName + ')';
    return new KeyValueModel(key, value);
  }

  /**
   * formatNumber() - Method to covert number to decimal places
   * @param inputNumber
   */
  formatNumber(inputNumber: number) {
    let decimalPipe = new DecimalPipe('en-US');
    if (inputNumber) {
      return decimalPipe.transform(inputNumber);
    }
    return '0';
  }

  /**
   * This method converts URLs into Hyperlinks, by preserving the text content around.
   * @param text 
   * @returns 
   */
   formatUrlLinks(text: string) {
    return (!text || text.length < 10) ? text :
      this.sanitizer.bypassSecurityTrustHtml(text?.replace(
        /\b(http.*?)(\.\s|\.$|\,\s|\,$|\:\s|\:$|\;\s|\;$|\s|$)/g,
        '<a href="$1" onclick="event.stopPropagation()" target="_blank">$1</a>$2'
      ));
  }

  /**
   * This method crops the input text according to the provided cropLength, and also crops trailing URLs.
   * @param input 
   * @param cropLength 
   * @returns 
   */
  cropComments(input: string, cropLength: number) {
    if (!input || input.length < cropLength) return input;
  
    let croppedInput = input.substring(0, cropLength);
  
    if (croppedInput.includes('http')) {
      const indexOfHttp = croppedInput.lastIndexOf('http');
      const isFullUrl =
        croppedInput.indexOf(' ', indexOfHttp + 4) ||
        croppedInput.indexOf(', ', indexOfHttp + 4) ||
        croppedInput.indexOf('. ', indexOfHttp + 4) ||
        croppedInput.indexOf('; ', indexOfHttp + 4) ||
        croppedInput.indexOf(': ', indexOfHttp + 4);
        if (isFullUrl >= 0) {
        return croppedInput + '...';
      } else {
        return croppedInput.substring(0, indexOfHttp) + '...';
      }
    } else {
      return croppedInput + '...';
    }
  }

  getServiceDirectionDisplayFormat(steeredServiceDirection: CASteeredService) {
    steeredServiceDirection.name = (steeredServiceDirection.name && steeredServiceDirection.name !== 'undefined') ? ' (' + steeredServiceDirection.name + ')' : '';
    return steeredServiceDirection.steeredServiceCd + (steeredServiceDirection.direction ? ' - ' + steeredServiceDirection.direction : '') + steeredServiceDirection.name;
  }

  /**
   * checkDateValidity() - Method to edit CA Request
   */
  dateCompare(startDate: string, endDate: string, dateToCheck: string): number {
    let startDateMoment = dayjs(startDate, CAT_DATE_FORMAT.DDMMMYYYY);
    let endDateMoment = dayjs(endDate, CAT_DATE_FORMAT.DDMMMYYYY);
    let dateToCheckMoment = dayjs(dateToCheck, CAT_DATE_FORMAT.DDMMMYYYY);

    if (dayjs(dateToCheckMoment).isAfter(endDateMoment)) {
      return 1;
    } else if (dayjs(startDateMoment).isAfter(dateToCheckMoment)) {
      return -1;
    }
    return 0;
  }

  /**
   * navigateToRequestDetail() - Method to navigate to Create Request/Request Details page
   * @param url
   */
  navigateToURL(url: string) {
    this.router.navigate([url]);
  }

  /**
   * isActiveRoute() - Method to check if the current Route is active
   * @param url
   */
  isActiveRoute(url: string): boolean {
    return this.router.isActive(url, true);
  }

  /**
   * getTile() - Method to set tile properties
   * @param headerText
   * @param imageName
   * @param text
   * @param link
   * @param linkText
   * @param permission
   * @param id
   */
  getTile(headerText, imageName, text, link, linkText, permission, id): Tile {
    let tile: Tile = new Tile();
    tile.headerText = headerText;
    tile.imagePath = imagePath + imageName;
    tile.text = text;
    tile.link = link;
    tile.linkText = linkText;
    tile.permission = permission;
    tile.id = id;
    return tile;
  }

  /**
   * cloneObject() - Method to deep copy a object
   * @param obj
   */
  cloneObject(obj) {
    let clone = {};
    for (let i in obj) {
      if (obj[i] != null && typeof (obj[i]) == 'object') {
        clone[i] = this.cloneObject(obj[i]);
      } else {
        clone[i] = obj[i];
      }
    }
    return clone;
  }

  /**
   * arraysEqual() - Method to check length and values of two arrays and retrun true or false.
   * @param arr1
   * @param arr2
   */
  arraysEqual(arr1, arr2): boolean {
    if (arr1.length !== arr2.length) {
      return false;
    } else {
      let numberOfMatchingElements = 0;
      for (let i = 0; i < arr1.length; i++) {
        for (let j = 0; j < arr2.length; j++) {
          if (arr1[i] === arr2[j]) {
            numberOfMatchingElements++;
          }
        }
      }
      return numberOfMatchingElements === arr1.length;


    }
  }

  /**
   * This function removes objects from the "inputArray" corresponding to the passed "key"
   * @param inputArray
   * @param key
   */
  removeObjectFromArrayByKey(inputArray: KeyValueModel[], key: string) {
    let arrayLength = inputArray.length;
    for (let i = arrayLength - 1; i >= 0; i--) {
      if (inputArray[i].key === key) {
        inputArray.splice(i, 1);
      }
    }
  }

  /**
   * This function add key-value object in the "targetArray".
   * @param targetArray
   * @param key
   * @param msg
   */
  addObjectToArrayByKey(targetArray: KeyValueModel[], key: string, msg: string) {
    targetArray.push({key: key, value: msg});
  }

  /**
   * This function returns 'true' if object with 'key' is present in 'inputArray', false otherwise.
   * @param inputArray
   * @param key
   */
  isObjectExistInArray(inputArray: KeyValueModel[], key: string): boolean {
    for (let i = 0; i < inputArray.length; i++) {
      if (inputArray[i].key === key) {
        return true;
      }
    }
    return false;
  }

  /**
   * This function replaces all instances of input 'charToReplace' inside 'targetUrl' with url encoder value and returns the changed string
   * @param targetUrl
   * @param charToReplace
   */
  replaceSpecialCharactersInString(targetUrl: string, charToReplace): string {
    let charCodeArray: any[] = [{key: '&', value: '%26'}];
    let regex = new RegExp(charToReplace, 'gi');
    charCodeArray?.forEach(entry => {
      if (entry.key === charToReplace) {
        targetUrl = targetUrl?.replace(regex, entry.value);
      }
    });
    return targetUrl;
  }

  /**
   * compareDates - compare the two date objects.
   * Returns
   *  1 if second date is greater
   *  0 if both date are equal
   * -1 if second date is lesser
   * @param startDate
   * @param endDate
   */
  compareDates(startDate: string, endDate: string): number {
    let startDateMoment = dayjs(startDate).format(CAT_DATE_FORMAT.DDMMMYYYY);
    let endDateMoment = dayjs(endDate).format(CAT_DATE_FORMAT.DDMMMYYYY);
    if (dayjs(endDateMoment).isAfter(startDateMoment)) {
      return 1;
    } else if (dayjs(startDateMoment).isAfter(endDateMoment)) {
      return -1;
    }
    return 0;
  }


  /**
   * loadTranslatedFieldDynamic() - Method to return the i18n converted field based on key and dynamic parameter passed
   * @param key
   * @param values
   */
  loadTranslatedFieldDynamic(key: string, values: any): string {
    let value = '';
    this.translate.get(key, values).subscribe((res: string) => {
      value = res;
    });
    return value;
  }

  /**
   * getKeyFromItemList() - Method to extract keys from item list
   * @param items
   */
  getKeyFromItemList(items: KeyValueModel[]): string[] {
    let keys: string[] = [];
    items?.forEach(item => {
      keys.push(item.key);
    });
    return keys;
  }

  /**
   * removeSemicolonFromItem() - Method to replace semicolon from string array
   * @param items
   */
  removeSemicolonFromItem(items: string[]): string[] {
    let newItem: string[] = [];
    items?.forEach(item => {
      item = item?.replace(';', '');
      newItem.push(item);
    });
    return newItem;
  }

  /**
   * onlyUnique() - Method to find unique from Array
   * @param value
   * @param index
   * @param self
   */
  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  /**
   * covertToElipsis() - Method to the given text to Elipsis format
   * @param text
   */
  covertToElipsis(text: string): string {
    return text.length > overSizeTextLimit ? (text.substring(0, overSizeTextLimit) + '...') : text;
  }

  /**
   * This is a utility function for replacing text in a string at a specified index.
   * @param targetString
   * @param index
   * @param textToBeReplaced
   * @param lenghtOfTextToBeReplaced
   */
  replaceAt(targetString, index, textToBeReplaced, lenghtOfTextToBeReplaced) {
    return targetString.substring(0, index) + textToBeReplaced + targetString.substring(index + lenghtOfTextToBeReplaced);
  }

  /**
   * getKeyValue() - Method to cenvert to KeyValue model
   * @param key
   * @param value
   */
  getKeyValue(key: string, value: string): KeyValueModel {
    return new KeyValueModel(key, value);
  }

  /**
   * This function returns deep clone of input "object" of generic type
   * @param object
   */
  getDeepCloneOfObject<T>(object: T): T {
    return JSON.parse(JSON.stringify(object));
  }

  /**
   * getMinStartDateAsStringInGMT() - Method to return the GMT +0 date as in mentioned format  which is current day - 14 days in the past
   */
  getMinStartDateAsStringInGMT(): string {
    dayjs.extend(utc);
    let minStartDate = this.getMinStartDateInGMT();
    return dayjs(minStartDate).utc().format(CAT_DATE_FORMAT.DDMMMYYYY);
  }

  /**
   * getMinStartDateInGMT() - Method to return the min StartDate in GMT +0 which is current day - 14 days in the past
   */
  getMinStartDateInGMT(): any {
    dayjs.extend(utc);
    return dayjs().utc().add(-14, 'day');
  }

  /**
   * getMinEndDateInGMT() - Method to return the min end date in GMT +0 which is current day & end date can not be a past date
   */
  getMinEndDateInGMT(): any {
    dayjs.extend(utc);
    return dayjs().utc();
  }

  /**
   * getMinEndDateInGMT() - Method to return the min end date in GMT +0 which is current day & end date can not be a past date
   */
  getMinEndDateAsStringInGMT(): string {
    dayjs.extend(utc);
    return dayjs().utc().format(CAT_DATE_FORMAT.DDMMMYYYY);
  }

  /**
   * getMaxEndDateInGMT() - Method to return the max EndDate in GMT +0
   */
  getMaxEndDateInGMT(): any {
    dayjs.extend(utc);
    let minStartDate = this.getMinStartDateInGMT();
    return dayjs(minStartDate).utc().add(587, 'day');
  }

  /**
   * getMaxEndDateInGMTAsStringInGMT() - Method to return the max EndDate in GMT +0 as in mentioned format
   */
  getMaxEndDateInGMTAsStringInGMT(): string {
    dayjs.extend(utc);
    let minStartDate = this.getMinStartDateInGMT();
    return dayjs(minStartDate).utc().add(587, 'day').format(CAT_DATE_FORMAT.DDMMMYYYY);
  }

  /**
   * checkIfStartDateBeforeToday() - Method to validate the start date (start date cannot be less then -14 days from current date as per GMT)
   */
  checkIfStartDateBeforeToday(responseDate) {
    let isStartBeforeToday = false;
    let responseStartDate = new Date(responseDate);
    let todayDate = new Date(this.getMinStartDateAsStringInGMT());
    //removing hours from weekly split
    todayDate.setHours(0, 0, 0, 0);
    if (responseStartDate < todayDate) {
      isStartBeforeToday = true;
    }
    return isStartBeforeToday;
  }

  /**
   * checkIfEndDateBeforeToday() - Method to validate the end date (end date cannot be less current date as per GMT)
   */
  checkIfEndDateBeforeToday(responseDate) {
    let isStartBeforeToday = false;
    let responseStartDate = new Date(responseDate);
    let todayDate = new Date(this.getMinEndDateAsStringInGMT());
    //removing hours from weekly split
    todayDate.setHours(0, 0, 0, 0);
    if (responseStartDate < todayDate) {
      isStartBeforeToday = true;
    }
    return isStartBeforeToday;
  }

  /**
   * This function concatenates the string array elements with the input "concatCharacter"
   * and returns the final string
   * @param inputArray
   * @param concatCharacter
   */
  stringConcat(inputArray: string[], concatCharacter: string): string {
    let result: string = '';
    if (inputArray && inputArray.length > 0) {
      result = inputArray?.join(concatCharacter);
    }
    return result;
  }

  /**
   * padZeros() - Method to add zero to the numbers if the value is less than the targeted size
   */
  padZeros(num: number, size: number): string {
    let tempString = num + '';
    while (tempString.length < size) {
      tempString = '0' + tempString;
    }
    return tempString;
  }

  /**
   * isObjectEmpty() - Method to check if JavaScript Object is empty or not
   */
  isObjectEmpty(obj: Object): boolean {
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }

  /**
   * This function returns a date after adding 'noOfDays' to 'inDate' with 'dateFormat'
   * @param inDate
   * @param dateFormat
   * @param noOfDays
   */
  addNoOfDaysToDate(inDate: string, dateFormat: string, noOfDays: number): string {
    let inDateMoment = dayjs(inDate, dateFormat);
    return inDateMoment.add(noOfDays, 'day').format(dateFormat);
  }

  /**
   * This function returns the week object corresponding to 'weekNumber' from the
   * 'weekList' object.
   * @param weekNumber
   * @param weekList
   */
  getWeekObjectFromWeekNumber(weekNumber: number, weekList: Weeks): Week {
    for (let i = 0; i < weekList.weeks.length; i++) {
      if (weekList.weeks[i].weekNumber == weekNumber) {
        return weekList.weeks[i];
      }
    }
  }

  /**
   * This function returns the week object corresponding to 'weekNumber' from the
   * 'weekList' object.
   * @param date
   * @param weekList
   */
  getWeekObjectFromGivenDate(date: string, weekList: Weeks): Week {
    for (let i = 0; i < weekList.weeks.length; i++) {
      if (weekList.weeks[i].date === date) {
        return weekList.weeks[i];
      }
    }
  }

  /**
   * This function returns name of the route corresponding to 'inRoute' object from
   * the superset 'carFiltersData' object.
   * @param inRoute
   * @param carFiltersData
   */
  extractRouteName(inRoute: Route, carFiltersData: CarFilters): string {
    for (let i = 0; i < carFiltersData.brands.length; i++) {
      for (let j = 0; j < carFiltersData.brands[i].routeDirections.length; j++) {
        if (carFiltersData.brands[i].routeDirections[j].routeCode === inRoute.routeCode
          &&
          carFiltersData.brands[i].routeDirections[j].routeDirection === inRoute.routeDirection) {
          return carFiltersData.brands[i].routeDirections[j].routeName;
        }
      }
    }
  }

  /**
   * This function returns name of the service corresponding to 'inService' object from
   * the superset 'carFiltersData' object.
   * @param inService
   * @param carFiltersData
   */
  extractServiceName(inRoute: Route,inService: Service, carFiltersData: CarFilters): string {
    for (let i = 0; i < carFiltersData.brands.length; i++) {
      for (let j = 0; j < carFiltersData.brands[i].routeDirections.length; j++) {
        if( carFiltersData.brands[i].routeDirections[j].routeDirection === inRoute.routeDirection && 
            carFiltersData.brands[i].routeDirections[j].routeCode === inRoute.routeCode ){
              for (let k = 0; k < carFiltersData.brands[i].routeDirections[j].serviceDirections.length; k++) {
                if (carFiltersData.brands[i].routeDirections[j].serviceDirections[k].serviceCode === inService.serviceCode &&
                    carFiltersData.brands[i].routeDirections[j].serviceDirections[k].serviceDirection === inService.serviceDirection) {
                    return carFiltersData.brands[i].routeDirections[j].serviceDirections[k].serviceName;
                }
              }
        }        
      }
    }
  }

  /**
   * This function returns a set of week numbers which comes between
   * startWeek.weekNumber and endWeek.weekNumber in the weeksList
   * @param startWeek
   * @param weeksList
   * @param endWeek
   */
  createWeekSet(startWeek: Week, weeksList: Weeks, endWeek: Week): Set<number> {
    let weekSet: Set<number> = new Set<number>();
    weeksList?.weeks?.forEach(week => {
      if (week.weekNumber >= startWeek?.weekNumber && week?.weekNumber <= endWeek?.weekNumber) {
        weekSet.add(week?.weekNumber);
      }
    });
    return weekSet;
  }

  /**
   * This is an utility function.
   * This function create and returns a drop down list from input array "inputCompositeArray" and the specified "key" and "value".
   * @param inputCompositeArray
   * @param key
   * @param value
   */
  generateDropDownComponentWithKeyValueFromCompositeArray(inputCompositeArray = [], key: string, value: string) {
    return inputCompositeArray.map(element => ({key: element[key], value: element[value]}));
  }

  /**
   * This is an utility function.
   * This function create and returns a drop down list from input array "inputCompositeArray" and the specified "key" and "value".
   * @param inputCompositeArray
   */
  generateDropDownComponentFromArray(inputCompositeArray = []) {
    return inputCompositeArray.map(element => ({key: element, value: element}));
  }

  /**
   * This is an utility function.
   * This function returns brand codes with comma separated e.g: MSL,MCC,SCL,SEA.
   * @param brandList
   */
  getAllBandCodesAsString(brandList: Brand[]): string {
    let brandCodes = [];
    brandList?.forEach(brand => {
      if (brand.code) {
        brandCodes.push(brand.code);
      }
    });
    return brandCodes?.join();
  }

  /**
   * This is an utility function.
   * This function returns brand details based on domain. This function needs to be in place since we don't get the
   * permitted brands based on the access to the brands for the domain group.
   * @param listOfPermittedBrands
   */
  getBrandListMappingBasedOnDomain(listOfPermittedBrands: Brand[]): Brand[] {
    let filteredPermittedBrandsList = [];
    const currentDomainUrl: string = this.domainService.currentDomain;
    let configuredBrands = [];

    if (currentDomainUrl.match(domainUrlRegExp.SEALAND)) {
      configuredBrands = applicationConstants.sealandDomainBrands;
    } else if (currentDomainUrl.match(domainUrlRegExp.HAS)) {
      configuredBrands = applicationConstants.hsudBrands;
    } else {
      configuredBrands = applicationConstants.maerskDomainBrands;
    }

    listOfPermittedBrands?.forEach(availableBrand => {
      configuredBrands?.forEach(brand => {
        if (availableBrand.code === brand) {
          filteredPermittedBrandsList.push(availableBrand);
        }
      });
    });

    return filteredPermittedBrandsList;
  }

  /**
   * getContractValidityTypeValue() - returns "NA" when it's not "Long term" or "Short term"
   * and returns display label "Long" for "Long term", "Short" for "Short term"
   * @param contractValidityType
   */
  getContractValidityTypeValue(contractValidityType: string): string {
    let validityTypeValue: string;
    if (contractValidityType && (contractValidityType.toUpperCase() === validityType.longTerm)) {
      validityTypeValue = validityType.long;
    } else if (contractValidityType && (contractValidityType.toUpperCase() === validityType.shortTerm)) {
      validityTypeValue = validityType.short;
    } else {
      validityTypeValue = this.loadTranslatedField('cat.genericLabels.none');
    }
    return validityTypeValue;
  }

  getOdAllocationTypeValue(odAllocationType: string): string {
    return odAllocationTypeOptionsList?.find(type => type.key === odAllocationType).value;
  }

  /**
   * getDashForContractValidityNone() - returns "-" for "None"
   * @param contractValidityType
   */
  getDashForContractValidityNone(contractValidityType
                                   :
                                   string
  ):
    string {
    let validityTypeValue: string;
    if (contractValidityType === validityType.none) {
      validityTypeValue = this.loadTranslatedField('cat.genericLabels.dash');
    } else {
      validityTypeValue = contractValidityType;
    }
    return validityTypeValue;

  }

  /* mapContractTypeLabel() - return None if contractType doesn't have value
  * @param contract
  */
  mapContractTypeLabel(contract
                         :
                         string
  ):
    string {
    let label: string = null;
    if (contract && ((contract.toUpperCase() === validityType.shortTerm) || (contract.toUpperCase() === validityType.longTerm))) {
      label = contract
    }
    return label;
  }

  /**
   * This function returns the matching quarter number from input "serviceWeeklySplit" by comparing "currentWeekDataObj" and
   * "uniqueWeekToQuarterMap" that contains mapping for unique week and quarter.
   * If there is no match then returns first quarter number.
   * @param serviceWeeklySplit
   * @param currentWeekDataObj
   * @param uniqueWeekToQuarterMap
   */
  calculateInitialQuarterForCurrentWeek(serviceWeeklySplit
                                          :
                                          ServiceWeeklySplit, currentWeekDataObj
                                          :
                                          Week, uniqueWeekToQuarterMap
                                          :
                                          Map<number, number>
  ):
    string {
    for (let quarter of serviceWeeklySplit.quarterlySplits) {
      for (let week of quarter.weekAllocations) {
        if (currentWeekDataObj.weekNumber === week?.weekNumber) {
          return uniqueWeekToQuarterMap.get(currentWeekDataObj?.weekNumber).toString();
        }
      }
    }
    return serviceWeeklySplit.quarterlySplits[0].quarter;
  }

  /**
   * It returns a map of type Map<number, number> using the master week data from API
   * It generates a map with each week as a 'key' and a its corresponding quarter that occurs first in master data as 'value'.
   * @param quarterWeekMasterDataList
   */
  prepareUniqueWeekToQuarterMap(quarterWeekMasterDataList
                                  :
                                  Weeks
  ):
    Map<number, number> {
    let resultMap
      :
      Map<number, number> = new Map<number, number>();
    let uniqueWeekMasterDataSet: Set<number> = new Set<number>();

    quarterWeekMasterDataList?.weeks?.forEach(week => {
      uniqueWeekMasterDataSet.add(week?.weekNumber);
    });
    let uniqueWeekMasterList: number[] = Array.from(uniqueWeekMasterDataSet);
    for (let uniqueWeek of uniqueWeekMasterList) {
      for (let week of quarterWeekMasterDataList.weeks) {
        if (uniqueWeek === week?.weekNumber) {
          resultMap.set(uniqueWeek, week.quarterNumber);
          break;
        }
      }
    }
    return resultMap;
  }

  checkIfStringContainsOnlyChars(input: string): boolean{
    let onlyCharRegex: string = '/[a-zA-Z]+/g';
    return input.match(onlyCharRegex) ? true: false
  }

}
